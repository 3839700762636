import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export default function ValidateUserRoute({ component: Component, ...rest }) {
    let extraProps = rest.extraProps
    delete rest.extraProps
    console.log(extraProps);
    let isLogin = localStorage.getItem("amplify-authenticator-authState") ==
        "signedIn"
    return (
        <Route
            {...rest}
            render={props =>
                isLogin ?
                    <Component {...props}  {...extraProps} />
                    :
                    <Redirect
                        to={{
                            pathname: "/",
                        }} />
            }
        />
    );
}
