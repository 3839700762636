import * as React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { DialogContent } from "@material-ui/core";
import useStyles from "../assests/css/styles";

function SimpleDialog(props) {
  const classes = useStyles();
  const {
    handleClose,
    open,
    title,
    // disableEscapeKeyDown,
    fullScreen,
    fullWidth,
  } = props;

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      // disableEscapeKeyDown={disableEscapeKeyDown}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      //   maxWidth={maxWidth}
      className={classes.dialogBox}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  // disableEscapeKeyDown: true,
};
export default SimpleDialog;
