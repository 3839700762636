import React, { useState, useEffect } from "react";
import useStyles from "../assests/css/styles";
import {
  CircularProgress,
  ListItem,
  ListItemText,
  Box,
  ListItemIcon,
  Grid,
} from "@material-ui/core";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { checkDeviceIP, getDeviceAndQueueList } from "../api";
import { getUserInfo } from "../services/getUserInfo";
import { ReactSVG } from "react-svg";
import deviceNotFound from "../assests/icon/Device-not-found.svg";
import qrScan from "../assests/icon/qr.png";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemote";
import { CModal } from ".";
import { Button } from "@aws-amplify/ui-react";
import { Header } from "../component/analytics/Header";
// import { getUserInfo } from "../services/getUserInfo";

const BillBoardScreen = (props) => {
  const history = useHistory();

  const classes = useStyles();

  const [checkIPResponse, setCheckIPResponse] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [deviceList, setDeviceList] = useState([]);
  const [userDetails, setUserDetails] = useState("");

  const handleClose = () => history.push("/");
  const handleDirect = () => {
    // console.log("http","`http://192.168.4.1?name=${open}`");
    window.open(`http://192.168.4.1/?name=${open}`, "_blank");
  };
  // const getUserDetails = async () => {
  //   const details = await getUserInfo();
  //   const userName = details.email && details.email.split("@")[0];
  //   console.log("userNameDevice", userName);
  //   const temp=userName.replace(/[.-]/g, "");
  //   // const idHash = `${temp}_${device}_${queue
  //   //   .replace(".json", "")
  //   //   .replace(".json", "")}`;
  //   setUserDetails();
  //   // fetchDeviceAndQueueList(userName);
  // };
  useEffect(() => {
    // getUserDetails();
    const userName = props.location.state.customProp.split("_")[0];
    const queueName = props.location.state.customProp.split("_")[2];
    const deviceName  = props.location.state.customProp.split("_")[1];
    const idHash = `${userName}_Billboard_${deviceName}_${queueName
      .replace(".json", "")
      .replace(".json", "")}`;
      setOpen(idHash)
    console.log("billii", idHash);
    console.log("billii", `http://192.168.4.1?name=${idHash}`);
    // console.log("billii");
    // console.log("billii");
  }, []);
  // const handleListClick = (device, queue) => {
  //   const idHash = `${userDetails}_${device}_${queue
  //     .replace(".json", "")
  //     .replace(".json", "")}`;
  //   // history.push(`/device-setting/${idHash}`)
  //   setOpen(idHash);
  // };
  return (
    <div className={classes.main}>
      {/* <Header /> */}
      {/* <div style={{textAlign:"center"}}>
        <p>Goto settings and Please connect to WIFI</p>
        <p>WiFi : BillBoard_QMD</p>
        <p>Password: 87654321</p>
      </div> */}
      <div style={{ marginTop: "110px" }}>
        <CModal open={Boolean(true)} handleClose={handleClose}>
          <div>
            <Box style={{ marginBottom: "30px" }}>
              <img src={qrScan} alt="QR code" />
            </Box>
            <p style={{ fontSize: 18 }}>Go to settings and Please connect to</p>
            <p style={{ fontSize: 18 }}>WiFi : BillBoard_QMD</p>
            <p style={{ fontSize: 18 }}>Password: 87654321</p>
            <Button
              onClick={() => handleDirect()}
              style={{
                backgroundColor: "#060543",
                color: "white",
                marginTop: "22px",
              }}
            >
              Connect
            </Button>
            {/* <Button
              onClick={() => history.push("/")}
              style={{
                backgroundColor: "#060543",
                color: "white",
                marginTop: "22px",
              }}
            >
              Go to Home
            </Button> */}
          </div>
        </CModal>
      </div>
    </div>
  );
};

export default BillBoardScreen;
