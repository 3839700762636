import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Grid,
    Typography,
    TextField,
    Modal,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Habilelabs from '../assests/images/habilelabs-squareLogo.png'

const useStyles = makeStyles((theme) => ({
    profileSection: {
        width: '340px',
        border: '1px solid #D7D7D7',
        boxShadow: '1px 1px 26px 0px rgba(132, 129, 138, 0.30)',
        borderRadius: '6px',
        padding: '20px 0px'
    },
    inputBox: {
        border: '1px solid #D7D7D7',
        height: '62px',
        display: 'block',
        background: 'rgba(241, 241, 241, 0.93)',
        marginLeft: '15px',
        marginRight: '15px',
        borderRadius: '4px',
        marginBottom: '20px',
        marginTop: '20px'
    },
    inputLabel: {
        color: '#B2B2B2',
        margin: '10px 0px 2px 13px',
        fontSize: '14px',
        fontWeight: 300,
    },
    inputField: {
        color: '#6C6C6C',
        margin: '0px 0px 10px 13px',
        fontSize: '16px',
        fontWeight: 400,
    }
}));

const MyProfile = (props) => {
    const email = props.location.state.email || 'No Email';
    const name = props.location.state.name
    const classes = useStyles();
    return (
        <Grid container className={classes.main} style={{ height: '100%' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.profile}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        zIndex: 1000,
                    }}
                >
                    <Box className={classes.profileSection}>
                        <div style={{ textAlign: 'center' }}>
                            <img
                                src={Habilelabs}
                                name="file"
                                className="img-thumbnail"
                                height={50}
                                width={50}
                                style={{ border: '1px solid gray', borderRadius: '50%', }}
                                alt="profile"
                            />
                        </div>
                        {
                            name && <Box className={classes.inputBox}>
                                <div className={classes.inputLabel}>Name</div>
                                <div className={classes.inputField} style={{ textTransform: 'lowercase' }}>{name}</div>
                            </Box>
                        }
                        <Box className={classes.inputBox}>
                            <div className={classes.inputLabel}>Email</div>
                            <div className={classes.inputField}>{email}</div>
                        </Box>

                    </Box>
                </div>
            </Grid>
        </Grid>
    )
}

export default MyProfile;