// import React, { useState, useEffect } from "react";
// import useStylesMain from "../assests/css/styles";
// import useStyles from "../assests/css/styles";
// import "../assests/css/style.css";
// import AuthDisplay from "../AuthDisplay";
// import { CContext } from "../component/auth-components/CContext";
// // import { uploadImageName } from "../api";
// import ProfileSetup from "./ProfileSetup";
// import {
//   Button,
//   CircularProgress,
//   ClickAwayListener,
//   Fab,
//   Grid,
//   ListItem,
//   ListItemText,
//   TextField,
//   Box,
//   ListItemIcon,
//   Modal,
//   Typography,
//   Input,
//   IconButton,
//   FormControlLabel,
//   Checkbox,
//   Drawer,
// } from "@material-ui/core";
// import { useHistory } from "react-router-dom";
// import { getUserInfo } from "../services/getUserInfo";
// import { ReactSVG } from "react-svg";
// import queueNotFound from "../assests/icon/Queue-not-found.svg";
// import Logo from "../assests/images/habilelabs_full_logo.svg";
// import dummyImage from "../assests/images/avatar.jpg";
// import { CModal } from "../../src/component/";
// import { PhotoCamera } from "@material-ui/icons";
// import { checkSpecialCharater, getBase64 } from "../services/utils";
// import { VisualViewport } from "../component/";
// import { Controller, useForm } from "react-hook-form";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
// import LoadingSpinner from "../component/auth-components/LoadingSpinner";
// import { sendUrlToBackendHandler } from "../../src/api";
// import { ReactComponent as DeviceIcon } from "../assests/images/DeviceIcon.svg";
// import Home from "../pages/Home";
// import Tooltip from '@material-ui/core/Tooltip';
// import Demo from '../component/Menu/SideNavigation'
// import { createQueue, getCurrentCount, getDeviceAndQueueList, getTotalCount, getUserImage, uploadImageName } from "../../src/api";
// import AddIcon from "@material-ui/icons/Add";
// import qrScan from "../assests/icon/qr.png";
// import useStyles1 from "../assests/css/profileStyle";
// import MenuIcon from "@mui/icons-material/Menu";
// import { Header } from "../../src/component/analytics/Header";

// const MainLayout = (props) => {
//   const history = useHistory();
//   const profileClasses = useStyles1();

//   const { authState, signOut } = props;
//   const { userDetailThroughProp } = props;
//   const classesMainLayout = useStylesMain();
//   const [userDetailProp, setuserDetailProp] = useState(
//     props.userDetailThroughProp
//   );
//   const { isModalOpen, setisModalOpen } = React.useContext(CContext);
//   const [bottomTabValue, setBottomTabValue] = useState(0);
//   const [userName, setUserName] = React.useState("");
//   const [nameContext, setnameContext] = useState("");
//   const [showImageError, setImageShowError] = React.useState(false);
//   const [selectedImg, setSelectedImg] = React.useState(null);
//   const [imageUpload, setImageUpload] = React.useState(false);
//   const [img, setImg] = React.useState("");
//   const [imageToupload, setimageToupload] = React.useState("");
//   const [nameValidationError, setnameValidationError] = useState("");
//   const classes = useStyles();
// const [isLoading, setIsLoading] = useState(false);
// const [newId, setNewId] = useState("");
// const [token, setToken] = useState("");
// const [addDevice, setAddDevice] = useState(false);
// const [userDetails, setUserDetails] = useState({});
// const name = userDetails.email && userDetails.email.split("@")[0];
// const [deviceList, setDeviceList] = useState([]);
//   const [open, setOpen] = React.useState(false);
//   const handleClose = () => setOpen(false);
//   const [currentNumber, setCurrentNumber] = useState(0);
//   const [totalCount, setTotalNumber] = useState({ Count: 0 });
//   const [openDrawer, setOpenDrawer] = React.useState(false);
//   const [isOpen, setIsOpen] = useState(true);

// const handleCloseDrawer = () => {
//   setOpenDrawer(false);
// };
//   const [openImgName, setOpenImgName] = useState(false);
//   const handleCloseImgName = () => setOpenImgName(false);
//   const [check, setCheck] = useState(true);
//   const [showDeviceError, setShowDeviceError] = useState(false);
//   const [withDevice, setWithDevice] = useState(false);
//   const [image, setImage] = useState(dummyImage);
//   const [userNameNew, setUserNameNew] = useState(name);
//   const { handleSubmit, control, resetField } = useForm();
// const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
// const [alert, setAlert] = useState({ message: "", type: "" });
// const [newValue, setNewValue] = useState(
//   localStorage.IsProfileSettingUP &&
//   localStorage.IsProfileSettingUP === "false"
// );
// const [isProfileSetup, setisProfileSetup] = useState(false);

// const fetchDeviceAndQueueList = (user) => {
//   console.log("fetchDeviceAndQueueList", user);
//   setIsLoading(true);
//   getDeviceAndQueueList(user)
//     .then((res) => {
//       setIsLoading(false);
//       setDeviceList(res);
//     })
//     .catch((err) => {
//       setIsLoading(false);
//     });
// };
//   React.useEffect(() => {
//     setnameContext(userNameFromContext && userNameFromContext.split("@")[0]);
//   }, [userNameFromContext]);
//   const { userNameFromContext, setuserNameFromContext } =
//     React.useContext(CContext);

//   React.useEffect(() => {
//     setBottomTabValue(
//       Number(localStorage.getItem("BottomNavigationScreenIndex"))
//     );
//   }, []);

// useEffect(() => {
//   getUserDetails();
//   if (!deviceList && !deviceList.length) {
//     setOpen(true);
//   }
// }, [name]);


// useEffect(() => {
//   if (authState === "signedIn") {
//     setisProfileSetup(localStorage.getItem("IsProfileSettingUP"));
//   }
// }, [authState]);

// useEffect(() => {
//   if (authState === "signedIn") {
//     getUserDetails();
//   }
// }, [authState]);

// const toggleDrawer = (event, deviceName) => {
//   const deviceToken = btoa(
//     `${name.replace(".", "")}_${deviceName.split("_")[1]}_${deviceName.split("_")[2]
//     }`
//   );
//   setOpenDrawer(!openDrawer);
//   setNewId(deviceName);
//   setToken(deviceToken);
//   getCurrentCount(deviceName)
//     .then((res) => {
//       setCurrentNumber(res.currentNum);
//     })
//     .catch((err) => {
//       // console.log(err.message);
//     });
//   getTotalCount(deviceName)
//     .then((res) => {
//       setTotalNumber(res);
//     })
//     .catch((err) => {
//     });
// };

// const getUserDetails = async () => {
//   const details = await getUserInfo();
//   setUserDetails(details);
//   const userName = details.email && details.email.split("@")[0];

//   fetchDeviceAndQueueList(userName);
// };

// const handleAddDevice = () => {
//   setAddDevice(true);
// };

// const handleDeviceAdded = () => {
//   setAddDevice(false);
// };
// const saveImageAndUserName = () => {
//   const userName = name + "_" + userNameNew;
//   localStorage["img"] = image;
//   uploadImageName(userName, image.split(",")[1]).then((res) => {
//     if (res.status === 200) {
//       const data = res.data.files.link;
//       setImage(data);
//       handleCloseImgName();
//     }
//   });
// };
// const handleUploadImg = (e) => {
//   const file = e.target.files[0];
//   if (file) {
//     getBase64(file).then((base64) => {
//       setImage(base64);
//       setCheck(!check);
//     });
//   }
// };
// const handleCheckChange = (event) => {
//   setWithDevice(event.target.checked);
// };
// const onSubmit = (input) => {
//   setIsLoadingSpinner(true);
//   if (name) {
//     let data = {
//       device: `${name}_${input.deviceName}_${input.queueName}`,
//     };
//     let deviceName = `${name}_${input.deviceName}_${input.queueName}`;
//     const hashId = btoa(
//       `${name.replace(".", "")}_${input.deviceName}_${input.queueName}`
//     );
//     sendUrlToBackendHandler(deviceName.replace(/[.-]/g, ""), hashId);
//     createQueue(data)
//       .then((res) => {
//         if (res.status == 502) {
//           setAlert({ message: res.data.files, type: "error" });
//           setTimeout(() => {
//             setAlert({ message: "", type: "" });
//           }, 3000);
//           setIsLoadingSpinner(false);
//         } else {
//           resetField("queueName");
//           resetField("deviceName");
//           withDevice && history.push(`/device/${hashId}`);
//           setIsLoading(false);
//           setAddDevice(false);
//           fetchDeviceAndQueueList(name);
//           setIsLoadingSpinner(false);
//         }
//       })
//       .catch((err) => {
//         // console.log(err.message);
//         setIsLoadingSpinner(false);
//       });
//   }
// };
//   console.log('ffregrgtrg', window.location)
// return authState === "signedIn" && !isModalOpen ? (
//   <>
//     {newValue !== undefined && !newValue ? (
//       <>
//         <ProfileSetup udpateValue={() => setNewValue(true)} />
//       </>
//     ) : (
//       <>
//         <div className={classesMainLayout.mainSection}>
//           <AuthDisplay />
//           <div className={classes.main}>
//             <Grid container>
//               <Grid item xs={4} sm={3} md={isOpen ? 2 : 1} style={{ position: 'relative', height: '100vh', boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)' }}>
//                 <Demo handleDeviceAdded={handleDeviceAdded} isOpen={isOpen} setIsOpen={setIsOpen} />
//               </Grid>
//               <Grid item xs={8} sm={9} md={isOpen ? 10 : 11}>
//                 <Header handleDeviceAdded={handleDeviceAdded} />
//                 {addDevice ? (
//                   <VisualViewport>
//                     {isLoading ? (
//                       <CircularProgress />
//                     ) : (
//                       <Box
//                         component="form"
//                         noValidate
//                         onSubmit={handleSubmit(onSubmit)}
//                       >
//                         <Grid container className={classes.addQueue}>
//                           <Grid
//                             item
//                             xs={12}
//                             md={6}
//                             style={{ textAlign: "center" }}
//                           >
//                             <DeviceIcon
//                               color="primary"
//                               className={classes.deviceIcon}
//                             />
//                           </Grid>
//                           <Grid item xs={12} md={6}>
//                             <b style={{ fontWeight: 1000 }}>
//                               Device Informations
//                             </b>
//                             <p className={classes.pModel}>
//                               Please provide details to identify your device.
//                             </p>
//                             <div>
//                               <Controller
//                                 name="queueName"
//                                 control={control}
//                                 defaultValue=""
//                                 rules={{
//                                   required: "Queue name cannot be empty",
//                                   pattern: {
//                                     value: /^[a-z0-9]+$/i,
//                                     message:
//                                       "Name cannot contain spaces or special characters!",
//                                   },
//                                 }}
//                                 render={({
//                                   field: { onChange, value },
//                                   fieldState: { error },
//                                 }) => (
//                                   <TextField
//                                     className={classes.textField}
//                                     value={value}
//                                     placeholder={"Queue Name "}
//                                     onChange={onChange}
//                                     error={!!error}
//                                     helperText={error ? error.message : " "}
//                                     InputProps={{
//                                       disableUnderline: true,
//                                       className: error
//                                         ? classes.inputError
//                                         : "",
//                                     }}
//                                     required
//                                   />
//                                 )}
//                               />
//                             </div>
//                             <div>
//                               <Controller
//                                 name="deviceName"
//                                 control={control}
//                                 defaultValue=""
//                                 rules={{
//                                   required: "Device name cannot be empty",
//                                   pattern: {
//                                     value: /^[a-z0-9]+$/i,
//                                     message:
//                                       "Name cannot contain spaces or special characters!",
//                                   },
//                                 }}
//                                 render={({
//                                   field: { onChange, value },
//                                   fieldState: { error },
//                                 }) => (
//                                   <TextField
//                                     className={classes.textField}
//                                     placeholder={"Device Name "}
//                                     onChange={onChange}
//                                     error={!!error}
//                                     helperText={error ? error.message : " "}
//                                     value={value}
//                                     autoComplete={false}
//                                     InputProps={{
//                                       disableUnderline: true,
//                                       className: error
//                                         ? classes.inputError
//                                         : "",
//                                     }}
//                                     required
//                                   />
//                                 )}
//                               />
//                             </div>
//                             <div>
//                               {alert.message && (
//                                 <span className={classes.warning}>
//                                   {alert.message}
//                                 </span>
//                               )}
//                             </div>
//                             <div className={classes.checkBox}>
//                               <FormControlLabel
//                                 control={
//                                   <Checkbox
//                                     checked={withDevice}
//                                     onChange={handleCheckChange}
//                                     className={classes.checkBox}
//                                   />
//                                 }
//                                 label="I want to connect with QM Device"
//                               />
//                             </div>
//                             {showDeviceError && (
//                               <span className={classes.warning}>
//                                 {showDeviceError}
//                               </span>
//                             )}

//                             <div>
//                               <Button variant="contained" type="submit" style={{ backgroundColor: '#060543' }}>
//                                 Add Now
//                                 {isLoadingSpinner ? <LoadingSpinner /> : null}
//                               </Button>
//                             </div>
//                           </Grid>
//                         </Grid>
//                       </Box>
//                     )}
//                   </VisualViewport>
//                 ) : (
//                   <div style={{ margin: "12px" }}>
//                     {!isLoading ? (
//                       deviceList && deviceList.length ? (
//                         <Grid container spacing={2}>
//                           {deviceList.map((list, index) => {
//                             const device = list.split("_")[1];
//                             const queue =
//                               list.split("_")[2] &&
//                               list
//                                 .split("_")[2]
//                                 .substring(
//                                   0,
//                                   list.split("_")[2].indexOf(".")
//                                 );
//                             const checkId = list.replace(".json", "");
//                             return (
//                               <Grid item xs={12} md={6} lg={4} sm={6}>
//                                 <ListItem
//                                   className={classes.listName}
//                                   component="div"
//                                   key={index}
//                                   onClick={(e) => toggleDrawer(e, checkId)}
//                                 >
//                                   <ListItemIcon
//                                     onClick={(e) => toggleDrawer(e, checkId)}
//                                     style={{
//                                       borderRight: "1px solid #D8D8D8",
//                                     }}
//                                   >
//                                     <DeviceIcon
//                                       color="primary"
//                                       style={{
//                                         height: "90px",
//                                         marginRight: "20px",
//                                       }}
//                                     />
//                                   </ListItemIcon>
//                                   <ListItemText
//                                     primary={queue}
//                                     onClick={(e) => toggleDrawer(e, checkId)}
//                                   ></ListItemText>
//                                 </ListItem>
//                                 {/* </div> */}
//                                 {!addDevice && (
//                                   <Grid
//                                     item
//                                     xs={1}
//                                     className={classes.addBtn}
//                                   >
//                                     <Tooltip title='Add Queue'>
//                                       <Fab
//                                         color="primary"
//                                         aria-label="add"
//                                         onClick={handleAddDevice}
//                                       >
//                                         <AddIcon />
//                                       </Fab>
//                                     </Tooltip>
//                                   </Grid>
//                                 )}
//                               </Grid>
//                             );
//                           })}
//                           <Drawer
//                             className={classes.drawer}
//                             variant={"temporary"}
//                             classes={{
//                               paper: classes.drawerPaper,
//                             }}
//                             anchor="right"
//                             open={openDrawer}
//                             onClose={handleCloseDrawer}
//                           >
//                             <div className={classes.toolbar} />
//                             <Home newId={newId} id={token} currentNumber={currentNumber} setCurrentNumber={setCurrentNumber} totalCount={totalCount} setTotalNumber={setTotalNumber} getUserDetails={getUserDetails} handleCloseDrawer={handleCloseDrawer} setOpenDrawer={setOpenDrawer} />
//                           </Drawer>
//                         </Grid>
//                       ) : (
//                         <Box className={classes.noQueue}>
//                           <ReactSVG src={queueNotFound} />
//                           <p>No Queue Found</p>
//                           <Grid item xs={12} style={{ marginTop: 50, marginBottom: 30 }}>
//                             <div>
//                               <Button style={{ backgroundColor: "#0386ee", color: "white", borderRadius: "7px" }} type="submit" onClick={handleAddDevice} >
//                                 Add Queue
//                               </Button>
//                             </div>
//                           </Grid>
//                         </Box>
//                       )
//                     ) : (
//                       <CircularProgress
//                         className={classes.queueLoader}
//                         color="primary"
//                       />
//                     )}
//                   </div>
//                 )}
//               </Grid>
//             </Grid>
//             <CModal open={open} handleClose={handleClose}>
//               <div>
//                 <Box>
//                   <img src={qrScan} alt="QR code" />
//                 </Box>
//                 <p>Please check internet connection then reload page</p>
//               </div>
//             </CModal>
//             <Modal
//               open={openImgName}
//               onClose={handleCloseImgName}
//               disableBackdropClick={true}
//               aria-labelledby="modal-modal-title"
//               aria-describedby="modal-modal-description"
//             >
//               <Box
//                 className={
//                   classes.modal + " " + profileClasses.changePassword
//                 }
//               >
//                 <Typography
//                   id="modal-modal-title"
//                   variant="h6"
//                   component="h2"
//                   className={profileClasses.modalHeader}
//                 >
//                   Enter Details
//                 </Typography>
//                 <img
//                   src={image}
//                   // alt="prifile_pic"
//                   name="file"
//                   className="img-thumbnail"
//                   height={100}
//                   width={100}
//                 />
//                 <Box>
//                   <label htmlFor="icon-button-file">
//                     <Input
//                       style={{ display: "none" }}
//                       className="form-control"
//                       name="file"
//                       accept="image/*"
//                       id="icon-button-file"
//                       type="file"
//                       onChange={handleUploadImg}
//                     />
//                     <IconButton
//                       color="primary"
//                       aria-label="upload picture"
//                       component="span"
//                       onClick={() => setCheck(!check)}
//                     >
//                       <PhotoCamera />
//                     </IconButton>
//                   </label>
//                 </Box>
//                 <TextField
//                   fullWidth
//                   className={profileClasses.modalBody}
//                   required
//                   placeholder="Enter Name here..."
//                   value={userNameNew}
//                   onChange={(e) => {
//                     setUserNameNew(e.target.value);
//                   }}
//                 />
//                 <Box className={profileClasses.modalFooter}>
//                   <Button
//                     variant="contained"
//                     color="success"
//                     size="medium"
//                     onClick={saveImageAndUserName}
//                     disabled={!userNameNew && check}
//                   >
//                     Submit
//                   </Button>
//                 </Box>
//               </Box>
//             </Modal>
//           </div>

//         </div>
//       </>
//     )}
//   </>
// ) : authState === "signedIn" && isModalOpen ? (
//   <>
//     <ProfileSetup udpateValue={() => setNewValue(true)} />
//   </>
// ) : null;
// };

// export default MainLayout;

// import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { ListItem, ListItemIcon, ListItemText, Drawer, FormControlLabel, Checkbox, CircularProgress, Button, AppBar, Toolbar, Typography, IconButton, Grid, Box, TextField, Fab, Modal, Input } from "@material-ui/core";
// import MenuIcon from "@material-ui/icons/Menu"; // Icon to toggle the sidebar
// import SideNavigation from "../component/Menu/SideNavigation";
// import Tooltip from '@material-ui/core/Tooltip';
// import { PhotoCamera } from "@material-ui/icons";
// import dummyImage from "../assests/images/avatar.jpg";
// import { CModal } from "../../src/component/";
// import qrScan from "../assests/icon/qr.png";
// import { ReactSVG } from "react-svg";
// import queueNotFound from "../assests/icon/Queue-not-found.svg";
// import { checkSpecialCharater, getBase64 } from "../services/utils";
// import useStyles1 from "../assests/css/profileStyle";
// import { getUserInfo } from "../services/getUserInfo";
// import { createQueue, getCurrentCount, getDeviceAndQueueList, getTotalCount, getUserImage, uploadImageName } from "../../src/api";
// import { VisualViewport } from "../component/";
// import { Controller, useForm } from "react-hook-form";
// import { sendUrlToBackendHandler } from "../../src/api";
// import { ReactComponent as DeviceIcon } from "../assests/images/DeviceIcon.svg";
// import AddIcon from "@material-ui/icons/Add";
// import Home from "../pages/Home";
// import LoadingSpinner from "../component/auth-components/LoadingSpinner";
// import { useHistory } from "react-router-dom";
// import ProfileSetup from "./ProfileSetup";
// import { CContext } from "../component/auth-components/CContext";
// import AuthDisplay from "../AuthDisplay";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//     height: "100vh",
//   },
//   appBar: {
//     width: (props) => (props.isOpen ? `calc(100% - 170px)` : `calc(100% - 100px)`),
//     // marginLeft: (props) => (props.isOpen ? 300 : 0),
//     transition: theme.transitions.create(["width", "margin"], {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//   },
//   content: {
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     overflowY: "auto",
//   },
//   toolbar: theme.mixins.toolbar,
// }));

// const MainLayout = (props) => {
//   const [isOpen, setIsOpen] = useState(true); // State to control sidebar visibility
// const [check, setCheck] = useState(true);
// const [userNameNew, setUserNameNew] = useState(name);
// const [image, setImage] = useState(dummyImage);
// const [openImgName, setOpenImgName] = useState(false);
// const handleCloseImgName = () => setOpenImgName(false);
// const handleClose = () => setOpen(false);
// const [openDrawer, setOpenDrawer] = React.useState(false);
// const [totalCount, setTotalNumber] = useState({ Count: 0 });
// const [open, setOpen] = React.useState(false);
// const [isLoading, setIsLoading] = useState(false);
// const [newId, setNewId] = useState("");
// const [token, setToken] = useState("");
// const [addDevice, setAddDevice] = useState(false);
// const [userDetails, setUserDetails] = useState({});
// const name = userDetails.email && userDetails.email.split("@")[0];
// const [deviceList, setDeviceList] = useState([]);
// const { handleSubmit, control, resetField } = useForm();
// const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
// const [alert, setAlert] = useState({ message: "", type: "" });
// const [withDevice, setWithDevice] = useState(false);
// const [showDeviceError, setShowDeviceError] = useState(false);
// const [currentNumber, setCurrentNumber] = useState(0);
// const [newValue, setNewValue] = useState(
//   localStorage.IsProfileSettingUP &&
//   localStorage.IsProfileSettingUP === "false"
// );
// const { isModalOpen, setisModalOpen } = React.useContext(CContext);
// const [isProfileSetup, setisProfileSetup] = useState(false);

// const { authState, signOut } = props;

// const history = useHistory();
// const classes = useStyles({ isOpen });
// const profileClasses = useStyles1();

// const toggleDrawer = () => setIsOpen(!isOpen);
// const saveImageAndUserName = () => {
//   const userName = name + "_" + userNameNew;
//   localStorage["img"] = image;
//   uploadImageName(userName, image.split(",")[1]).then((res) => {
//     if (res.status === 200) {
//       const data = res.data.files.link;
//       setImage(data);
//       handleCloseImgName();
//     }
//   });
// };
// const handleUploadImg = (e) => {
//   const file = e.target.files[0];
//   if (file) {
//     getBase64(file).then((base64) => {
//       setImage(base64);
//       setCheck(!check);
//     });
//   }
// };
// const getUserDetails = async () => {
//   const details = await getUserInfo();
//   setUserDetails(details);
//   const userName = details.email && details.email.split("@")[0];

//   fetchDeviceAndQueueList(userName);
// };
// const handleCloseDrawer = () => {
//   setOpenDrawer(false);
// };
// const handleAddDevice = () => {
//   setAddDevice(true);
// };
// const fetchDeviceAndQueueList = (user) => {
//   console.log("fetchDeviceAndQueueList", user);
//   setIsLoading(true);
//   getDeviceAndQueueList(user)
//     .then((res) => {
//       setIsLoading(false);
//       setDeviceList(res);
//     })
//     .catch((err) => {
//       setIsLoading(false);
//     });
// };
// const handleCheckChange = (event) => {
//   setWithDevice(event.target.checked);
// };
// useEffect(() => {
//   getUserDetails();
//   if (!deviceList && !deviceList.length) {
//     setOpen(true);
//   }
// }, [name]);
// useEffect(() => {
//   if (authState === "signedIn") {
//     setisProfileSetup(localStorage.getItem("IsProfileSettingUP"));
//   }
// }, [authState]);

// useEffect(() => {
//   if (authState === "signedIn") {
//     getUserDetails();
//   }
// }, [authState]);
// const toggleDrawer1 = (event, deviceName) => {
//   const deviceToken = btoa(
//     `${name.replace(".", "")}_${deviceName.split("_")[1]}_${deviceName.split("_")[2]
//     }`
//   );
//   setOpenDrawer(!openDrawer);
//   setNewId(deviceName);
//   setToken(deviceToken);
//   getCurrentCount(deviceName)
//     .then((res) => {
//       setCurrentNumber(res.currentNum);
//     })
//     .catch((err) => {
//       // console.log(err.message);
//     });
//   getTotalCount(deviceName)
//     .then((res) => {
//       setTotalNumber(res);
//     })
//     .catch((err) => {
//     });
// };
// const onSubmit = (input) => {
//   setIsLoadingSpinner(true);
//   if (name) {
//     let data = {
//       device: `${name}_${input.deviceName}_${input.queueName}`,
//     };
//     let deviceName = `${name}_${input.deviceName}_${input.queueName}`;
//     const hashId = btoa(
//       `${name.replace(".", "")}_${input.deviceName}_${input.queueName}`
//     );
//     sendUrlToBackendHandler(deviceName.replace(/[.-]/g, ""), hashId);
//     createQueue(data)
//       .then((res) => {
//         if (res.status == 502) {
//           setAlert({ message: res.data.files, type: "error" });
//           setTimeout(() => {
//             setAlert({ message: "", type: "" });
//           }, 3000);
//           setIsLoadingSpinner(false);
//         } else {
//           resetField("queueName");
//           resetField("deviceName");
//           withDevice && history.push(`/device/${hashId}`);
//           setIsLoading(false);
//           setAddDevice(false);
//           fetchDeviceAndQueueList(name);
//           setIsLoadingSpinner(false);
//         }
//       })
//       .catch((err) => {
//         // console.log(err.message);
//         setIsLoadingSpinner(false);
//       });
//   }
// };
// const handleDeviceAdded = () => {
//   setAddDevice(false);
// };
//   return (
//     <div className={classes.root}>
//       <SideNavigation isOpen={isOpen} toggleDrawer={toggleDrawer} handleDeviceAdded={handleDeviceAdded} />

//       <AppBar position="fixed" className={classes.appBar}>
//         <Toolbar>
//           <Typography variant="h6" noWrap>
//             Fixed Header
//           </Typography>
//         </Toolbar>
//       </AppBar>
// {
//   window.location.pathname === '/' ?
//     <main className={classes.content}>
//       <div className={classes.toolbar} />
//       {
//         authState === "signedIn" && !isModalOpen ? (
//           <>
//             {
//               newValue !== undefined && !newValue ? (
//                 <>
//                   <ProfileSetup udpateValue={() => setNewValue(true)} />
//                 </>
//               ) : (
//                 <div>
//                   <Grid container>
//                     <AuthDisplay />
//                     <Grid >
//                       {addDevice ? (
//                         <VisualViewport>
//                           {isLoading ? (
//                             <CircularProgress />
//                           ) : (
//                             <Box
//                               component="form"
//                               noValidate
//                               onSubmit={handleSubmit(onSubmit)}
//                             >
//                               <Grid container className={classes.addQueue}>
//                                 <Grid
//                                   item
//                                   xs={12}
//                                   md={6}
//                                   style={{ textAlign: "center" }}
//                                 >
//                                   <DeviceIcon
//                                     color="primary"
//                                     className={classes.deviceIcon}
//                                   />
//                                 </Grid>
//                                 <Grid item xs={12} md={6}>
//                                   <b style={{ fontWeight: 1000 }}>
//                                     Device Informations
//                                   </b>
//                                   <p className={classes.pModel}>
//                                     Please provide details to identify your device.
//                                   </p>
//                                   <div>
//                                     <Controller
//                                       name="queueName"
//                                       control={control}
//                                       defaultValue=""
//                                       rules={{
//                                         required: "Queue name cannot be empty",
//                                         pattern: {
//                                           value: /^[a-z0-9]+$/i,
//                                           message:
//                                             "Name cannot contain spaces or special characters!",
//                                         },
//                                       }}
//                                       render={({
//                                         field: { onChange, value },
//                                         fieldState: { error },
//                                       }) => (
//                                         <TextField
//                                           className={classes.textField}
//                                           value={value}
//                                           placeholder={"Queue Name "}
//                                           onChange={onChange}
//                                           error={!!error}
//                                           helperText={error ? error.message : " "}
//                                           InputProps={{
//                                             disableUnderline: true,
//                                             className: error
//                                               ? classes.inputError
//                                               : "",
//                                           }}
//                                           required
//                                         />
//                                       )}
//                                     />
//                                   </div>
//                                   <div>
//                                     <Controller
//                                       name="deviceName"
//                                       control={control}
//                                       defaultValue=""
//                                       rules={{
//                                         required: "Device name cannot be empty",
//                                         pattern: {
//                                           value: /^[a-z0-9]+$/i,
//                                           message:
//                                             "Name cannot contain spaces or special characters!",
//                                         },
//                                       }}
//                                       render={({
//                                         field: { onChange, value },
//                                         fieldState: { error },
//                                       }) => (
//                                         <TextField
//                                           className={classes.textField}
//                                           placeholder={"Device Name "}
//                                           onChange={onChange}
//                                           error={!!error}
//                                           helperText={error ? error.message : " "}
//                                           value={value}
//                                           autoComplete={false}
//                                           InputProps={{
//                                             disableUnderline: true,
//                                             className: error
//                                               ? classes.inputError
//                                               : "",
//                                           }}
//                                           required
//                                         />
//                                       )}
//                                     />
//                                   </div>
//                                   <div>
//                                     {alert.message && (
//                                       <span className={classes.warning}>
//                                         {alert.message}
//                                       </span>
//                                     )}
//                                   </div>
//                                   <div className={classes.checkBox}>
//                                     <FormControlLabel
//                                       control={
//                                         <Checkbox
//                                           checked={withDevice}
//                                           onChange={handleCheckChange}
//                                           className={classes.checkBox}
//                                         />
//                                       }
//                                       label="I want to connect with QM Device"
//                                     />
//                                   </div>
//                                   {showDeviceError && (
//                                     <span className={classes.warning}>
//                                       {showDeviceError}
//                                     </span>
//                                   )}

//                                   <div>
//                                     <Button variant="contained" type="submit" style={{ backgroundColor: '#060543' }}>
//                                       Add Now
//                                       {isLoadingSpinner ? <LoadingSpinner /> : null}
//                                     </Button>
//                                   </div>
//                                 </Grid>
//                               </Grid>
//                             </Box>
//                           )}
//                         </VisualViewport>
//                       ) : (
//                         <div style={{ margin: "12px" }}>
//                           {!isLoading ? (
//                             deviceList && deviceList.length ? (
//                               <>
//                                 <Grid container spacing={2}>
//                                   {deviceList.map((list, index) => {
//                                     const device = list.split("_")[1];
//                                     const queue =
//                                       list.split("_")[2] &&
//                                       list
//                                         .split("_")[2]
//                                         .substring(
//                                           0,
//                                           list.split("_")[2].indexOf(".")
//                                         );
//                                     const checkId = list.replace(".json", "");
//                                     return (
//                                       <Grid item xs={12} md={6} lg={4} sm={6}>
//                                         <ListItem
//                                           className={classes.listName}
//                                           component="div"
//                                           key={index}
//                                           onClick={(e) => toggleDrawer1(e, checkId)}
//                                         >
//                                           <ListItemIcon
//                                             onClick={(e) => toggleDrawer1(e, checkId)}
//                                             style={{
//                                               borderRight: "1px solid #D8D8D8",
//                                             }}
//                                           >
//                                             <DeviceIcon
//                                               color="primary"
//                                               style={{
//                                                 height: "90px",
//                                                 marginRight: "20px",
//                                               }}
//                                             />
//                                           </ListItemIcon>
//                                           <ListItemText
//                                             primary={queue}
//                                             onClick={(e) => toggleDrawer1(e, checkId)}
//                                           ></ListItemText>
//                                         </ListItem>
//                                         {/* </div> */}
//                                       </Grid>
//                                     );
//                                   })}
//                                   <Drawer
//                                     className={classes.drawer}
//                                     variant={"temporary"}
//                                     classes={{
//                                       paper: classes.drawerPaper,
//                                     }}
//                                     anchor="right"
//                                     open={openDrawer}
//                                     onClose={handleCloseDrawer}
//                                   >
//                                     <div className={classes.toolbar} />
//                                     <Home newId={newId} id={token} currentNumber={currentNumber} setCurrentNumber={setCurrentNumber} totalCount={totalCount} setTotalNumber={setTotalNumber} getUserDetails={getUserDetails} handleCloseDrawer={handleCloseDrawer} setOpenDrawer={setOpenDrawer} />
//                                   </Drawer>
//                                 </Grid>
//                                 {!addDevice && (
//                                   <Grid
//                                     item
//                                     xs={1}
//                                     className={classes.addBtn}
//                                     style={{ position: 'fixed', bottom: '50px', right: '20px' }}
//                                   >
//                                     <Tooltip title='Add Queue'>
//                                       <Fab
//                                         color="primary"
//                                         aria-label="add"
//                                         onClick={handleAddDevice}
//                                       >
//                                         <AddIcon />
//                                       </Fab>
//                                     </Tooltip>
//                                   </Grid>
//                                 )}
//                               </>
//                             ) : (
//                               <Box className={classes.noQueue}>
//                                 <ReactSVG src={queueNotFound} />
//                                 <p>No Queue Found</p>
//                                 <Grid item xs={12} style={{ marginTop: 50, marginBottom: 30 }}>
//                                   <div>
//                                     <Button style={{ backgroundColor: "#0386ee", color: "white", borderRadius: "7px" }} type="submit" onClick={handleAddDevice} >
//                                       Add Queue
//                                     </Button>
//                                   </div>
//                                 </Grid>
//                               </Box>
//                             )
//                           ) : (
//                             <CircularProgress
//                               className={classes.queueLoader}
//                               color="primary"
//                             />
//                           )}
//                         </div>
//                       )}
//                     </Grid>
//                   </Grid>
//                   <CModal open={open} handleClose={handleClose}>
//                     <div>
//                       <Box>
//                         <img src={qrScan} alt="QR code" />
//                       </Box>
//                       <p>Please check internet connection then reload page</p>
//                     </div>
//                   </CModal>
//                   <Modal
//                     open={openImgName}
//                     onClose={handleCloseImgName}
//                     disableBackdropClick={true}
//                     aria-labelledby="modal-modal-title"
//                     aria-describedby="modal-modal-description"
//                   >
//                     <Box
//                       className={
//                         classes.modal + " " + profileClasses.changePassword
//                       }
//                     >
//                       <Typography
//                         id="modal-modal-title"
//                         variant="h6"
//                         component="h2"
//                         className={profileClasses.modalHeader}
//                       >
//                         Enter Details
//                       </Typography>
//                       <img
//                         src={image}
//                         // alt="prifile_pic"
//                         name="file"
//                         className="img-thumbnail"
//                         height={100}
//                         width={100}
//                       />
//                       <Box>
//                         <label htmlFor="icon-button-file">
//                           <Input
//                             style={{ display: "none" }}
//                             className="form-control"
//                             name="file"
//                             accept="image/*"
//                             id="icon-button-file"
//                             type="file"
//                             onChange={handleUploadImg}
//                           />
//                           <IconButton
//                             color="primary"
//                             aria-label="upload picture"
//                             component="span"
//                             onClick={() => setCheck(!check)}
//                           >
//                             <PhotoCamera />
//                           </IconButton>
//                         </label>
//                       </Box>
//                       <TextField
//                         fullWidth
//                         className={profileClasses.modalBody}
//                         required
//                         placeholder="Enter Name here..."
//                         value={userNameNew}
//                         onChange={(e) => {
//                           setUserNameNew(e.target.value);
//                         }}
//                       />
//                       <Box className={profileClasses.modalFooter}>
//                         <Button
//                           variant="contained"
//                           color="success"
//                           size="medium"
//                           onClick={saveImageAndUserName}
//                           disabled={!userNameNew && check}
//                         >marginBottom: '20px', 
//                           Submit
//                         </Button>
//                       </Box>
//                     </Box>
//                   </Modal>
//                 </div>
//               )
//             }
//           </>
//         ) : authState === "signedIn" && isModalOpen ? (
//           <>
//             <ProfileSetup udpateValue={() => setNewValue(true)} />
//           </>
//         ) : null
//       }
//     </main> : <></>
// }

// <main className={classes.content}>
//   <div className={classes.toolbar} />
//   {props.children}
// </main>
//     </div>
//   );
// };

// export default React.memo(MainLayout);
import React, { useState, useEffect, useRef, useCallback } from "react";
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useStyles from "../assests/css/styles";
import ListItemButton from '@mui/material/ListItemButton';
import { makeStyles } from "@material-ui/core/styles";
import { ListItem, ListItemIcon, ListItemText, useMediaQuery, FormControlLabel, Checkbox, CircularProgress, Button, Toolbar, Typography, IconButton, Grid, Box, TextField, Fab, Modal, Input, Drawer as Drawer1, Drawer as Drawer2 } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu"; // Icon to toggle the sidebar
import SideNavigation from "../component/Menu/SideNavigation";
import Tooltip from '@material-ui/core/Tooltip';
import { PhotoCamera } from "@material-ui/icons";
import dummyImage from "../assests/images/avatar.jpg";
import { CModal } from "../../src/component/";
import qrScan from "../assests/icon/qr.png";
import qmdImage from "../assests/icon/QMD_IMAGE_1.png"
import { ReactSVG } from "react-svg";
import queueNotFound from "../assests/icon/Queue-not-found.svg";
import { checkSpecialCharater, getBase64 } from "../services/utils";
import useStyles1 from "../assests/css/profileStyle";
import { getUserInfo } from "../services/getUserInfo";
import { createQueue, getCurrentCount, getDeviceAndQueueList, getTotalCount, getUserImage, uploadImageName } from "../../src/api";
import { VisualViewport } from "../component/";
import { Controller, useForm } from "react-hook-form";
import { sendUrlToBackendHandler } from "../../src/api";
import { ReactComponent as DeviceIcon } from "../assests/images/DeviceIcon.svg";
import AddIcon from "@material-ui/icons/Add";
import Home from "../pages/Home";
import LoadingSpinner from "../component/auth-components/LoadingSpinner";
import { useHistory } from "react-router-dom";
import ProfileSetup from "./ProfileSetup";
import { CContext } from "../component/auth-components/CContext";
import AuthDisplay from "../AuthDisplay";
import habilelabsLogo from "../assests/images/habilelabs-logo.svg"
import LogOut1 from "../assests/images/logout1.svg";
import Profile from "../assests/images/profile.svg";
import { debounce } from 'lodash';

const drawerWidth = 240;
const mobileDrawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
const CustomDrawer2 = styled(Drawer2, { shouldForwardProp: (prop) => prop !== 'sideDrawerOpen' })(
  ({ theme, sideDrawerOpen }) => ({
    width: mobileDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(sideDrawerOpen && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
        width: mobileDrawerWidth,
      },
    }),
    ...(!sideDrawerOpen && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        width: mobileDrawerWidth,
      },
    }),
  }),
);

export default function MainLayout(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = React.useState(!isMobile);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const [check, setCheck] = useState(true);
  const [userNameNew, setUserNameNew] = useState(name);
  const [image, setImage] = useState(dummyImage);
  const [openImgName, setOpenImgName] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const handleCloseImgName = () => setOpenImgName(false);
  const handleClose = () => setIsOpen(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [totalCount, setTotalNumber] = useState({ Count: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [newId, setNewId] = useState("");
  const [token, setToken] = useState("");
  const [addDevice, setAddDevice] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const name = userDetails.email && userDetails.email.split("@")[0];
  const [deviceList, setDeviceList] = useState([]);
  const { handleSubmit, control, resetField } = useForm();
  const [isLoadingSpinner, setIsLoadingSpinner] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const [withDevice, setWithDevice] = useState(false);
  const [showDeviceError, setShowDeviceError] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(0);
  const [test1, setTest1] = useState(false)
  const [newValue, setNewValue] = useState(
    localStorage.IsProfileSettingUP &&
    localStorage.IsProfileSettingUP === "false"
  );
  const { isModalOpen, setisModalOpen } = React.useContext(CContext);
  const [isProfileSetup, setisProfileSetup] = useState(false);
  const [img, setImg] = useState('');
  const [userName, setUserName] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [logoutOpen, setLogoutOpen] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const timeoutRef = useRef(null);
  const { authState, signOut } = props;

  const history = useHistory();
  const classes = useStyles();
  const profileClasses = useStyles1();

  const toggleDrawer = () => setOpen(!open);
  const saveImageAndUserName = () => {
    const userName = name + "_" + userNameNew;
    localStorage["img"] = image;
    uploadImageName(userName, image.split(",")[1]).then((res) => {
      if (res.status === 200) {
        const data = res.data.files.link;
        setImage(data);
        handleCloseImgName();
      }
    });
  };
  const handleOpen = () => setLogoutOpen(true);
  const handlelogoutClose = () => setLogoutOpen(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };


  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleResize = useCallback(
    debounce(() => {
      const currentWidth = window.innerWidth;
      console.log("Current Width:", currentWidth, "Open State:", open); // Debug logs for verification

      if (currentWidth > 500 && !open) {
        setOpen(true);
      } else if (currentWidth <= 500 && open) {
        setOpen(false);
      }
    }, 200), // 200ms delay to prevent too frequent updates
    [open]
  );
  // useEffect(() => {
  //   // Attach resize event listener
  //   window.addEventListener('resize', handleResize);

  //   // Call once to sync state with the current width
  //   handleResize();

  //   // Cleanup on unmount
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //     handleResize.cancel(); // Cancel any pending debounced function calls
  //   };
  // }, [handleResize]);

  const handleUploadImg = (e) => {
    const file = e.target.files[0];
    if (file) {
      getBase64(file).then((base64) => {
        setImage(base64);
        setCheck(!check);
      });
    }
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const handleAddDevice = () => {
    setAddDevice(true);
  };
  const fetchDeviceAndQueueList = (user) => {
    setIsLoading(true);
    getDeviceAndQueueList(user)
      .then((res) => {
        setIsLoading(false);
        setDeviceList(res);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const handleCheckChange = (event) => {
    setWithDevice(event.target.checked);
  };
  useEffect(() => {
    getUserDetails();
    if (!deviceList && !deviceList.length) {
      setIsOpen(true);
    }
  }, [name]);
  useEffect(() => {
    if (authState === "signedIn") {
      setisProfileSetup(localStorage.getItem("IsProfileSettingUP"));
    }
  }, [authState]);

  useEffect(() => {
    if (authState === "signedIn") {
      getUserDetails();
    }
  }, [authState]);
  const toggleDrawer1 = (event, deviceName) => {
    const deviceToken = btoa(
      `${name.replace(".", "")}_${deviceName.split("_")[1]}_${deviceName.split("_")[2]
      }`
    );


    setOpenDrawer(!openDrawer);

    setNewId(deviceName);
    setToken(deviceToken);
    getCurrentCount(deviceName)
      .then((res) => {
        setCurrentNumber(res.currentNum);
      })
      .catch((err) => {
        console.log('dwfewfrrf', err);
      });
    getTotalCount(deviceName)
      .then((res) => {
        setTotalNumber(res);
      })
      .catch((err) => {
      });
  };
  const onSubmit = (input) => {
    setIsLoadingSpinner(true);
    if (name) {
      let data = {
        device: `${name}_${input.deviceName}_${input.queueName}`,
      };
      let deviceName = `${name}_${input.deviceName}_${input.queueName}`;
      const hashId = btoa(
        `${name.replace(".", "")}_${input.deviceName}_${input.queueName}`
      );
      sendUrlToBackendHandler(deviceName.replace(/[.-]/g, ""), hashId);
      createQueue(data)
        .then((res) => {
          if (res.status == 502) {
            setAlert({ message: res.data.files, type: "error" });
            setTimeout(() => {
              setAlert({ message: "", type: "" });
            }, 3000);
            setIsLoadingSpinner(false);
          } else {
            resetField("queueName");
            resetField("deviceName");
            withDevice && history.push(`/device/${hashId}`);
            setIsLoading(false);
            setAddDevice(false);
            fetchDeviceAndQueueList(name);
            setIsLoadingSpinner(false);
          }
        })
        .catch((err) => {
          // console.log(err.message);
          setIsLoadingSpinner(false);
        });
    }
  };
  const handleDeviceAdded = () => {
    setAddDevice(false);
  };
  const getUserEmailUserName = async () => {
    setIsLoading(true);
    const imageData = await getUserImage(name);
    if (imageData) {
      setImg(imageData.files.link);
      const data = imageData.files.link;
      setUserName(
        data
          .substr(data.lastIndexOf("/") + 1)
          .split(".")[0]
          .split("_")[1]
      );
      if (imageData.files.link) {
        setIsLoading(false);
      }
    }
  };

  const getUserDetails = async () => {
    const details = await getUserInfo();
    setUserDetails(details);
    const userName = details.email && details.email.split("@")[0];

    fetchDeviceAndQueueList(userName);
  };
  const getUserData = async () => {
    const details = await getUserInfo();
    setUserDetails(details);
  };
  useEffect(() => {
    if (name) {
      getUserEmailUserName();
    }
  }, [name]);
  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    getUserData();
  }, []);
  function getInitials(name) {
    const words = name.trim().split(" ");
    if (words.length === 1) {
      return words[0][0].toUpperCase();
    }
    const firstInitial = words[0][0].toUpperCase();
    const lastInitial = words[words.length - 1][0].toUpperCase();
    return firstInitial + lastInitial;
  }

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setShowMenu(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => setShowMenu(false), 500);
  };

  const handleMenuClick = (path) => {
    setShowMenu(false);
    history.push(path, {
      email: userDetails.email,
      name: userName
    });
  };

  const menuItemStyle = {
    padding: '8px 15px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    color: '#000',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
  };


  const sideToggleDrawer = (open) => {
    setSideDrawerOpen(open);
  };
  console.log('fhfhfhfhfhf', isMobile, sideDrawerOpen)

  return props.authData || props.children ? (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {
        newValue !== undefined && !newValue ?
          <>
          </>
          :
          <>
            <AppBar position="fixed" open={open} style={{ backgroundColor: '#060543', width: isMobile ? '100%' : open ? `calc(100% - ${drawerWidth}px)` : '100%' }}>
              <Toolbar>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={isMobile ? sideToggleDrawer : handleDrawerOpen}
                  edge="start"
                  sx={[
                    {
                      marginRight: 5,
                    },
                  ]}
                >
                  {
                    isMobile ? <MenuIcon /> : open ? <></> : <MenuIcon />
                  }
                </IconButton>
                <div
                  style={{ color: '#FFF', position: 'absolute', right: '10px' }}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Typography
                    className={classes.userName}
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', background: '#fff', marginBottom: '0px', color: '#000', padding: '10px' }}
                  >
                    {userName ? getInitials(userName) : ""}
                  </Typography>
                  {showMenu && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '50px',
                        right: '0',
                        backgroundColor: '#fff',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '8px',
                        zIndex: 10,
                        padding: '10px 0px',
                        width: '150px',
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      <div
                        style={menuItemStyle}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                        onClick={() => handleMenuClick('/my-profile')}
                      >
                        <ReactSVG src={Profile} />
                        <p style={{ margin: '0px', paddingLeft: '5px' }}>View Profile</p>
                      </div>
                      <div
                        style={menuItemStyle}
                        onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                        onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '')}
                        onClick={handleOpen}
                      >
                        <ReactSVG src={LogOut1} />
                        <p style={{ margin: '0px', paddingLeft: '5px' }}>Log Out</p>
                      </div>
                    </div>
                  )}
                </div>
              </Toolbar>
            </AppBar>
            {
              isMobile && sideDrawerOpen && (
                <CustomDrawer2 open={sideDrawerOpen} onClose={() => sideToggleDrawer(false)}>
                  <DrawerHeader style={{ justifyContent: 'center', position: 'relative', top: '8px' }}>
                    <ReactSVG src={habilelabsLogo} />
                  </DrawerHeader>
                  <Divider />
                  <List style={{ height: '100%' }}>
                    <SideNavigation setSideDrawerOpen={setSideDrawerOpen} isMobile={isMobile} handlelogoutClose={handlelogoutClose} logoutOpen={logoutOpen} isOpen={sideDrawerOpen} toggleDrawer={toggleDrawer} handleDeviceAdded={handleDeviceAdded} />
                  </List>
                  <Divider />
                </CustomDrawer2>
              )
            }
            {!isMobile && (
              <Drawer
                variant='permanent'
                open={open}
              >
                <DrawerHeader>
                  <ReactSVG src={habilelabsLogo} />
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                </DrawerHeader>
                <Divider />

                <List style={{ height: '100%' }}>
                  <SideNavigation handlelogoutClose={handlelogoutClose} logoutOpen={logoutOpen} isOpen={open} toggleDrawer={toggleDrawer} handleDeviceAdded={handleDeviceAdded} />
                </List>
                <Divider />
              </Drawer>
            )}
          </>
      }

      <Box component="main" sx={{ flexGrow: 1, height: '100vh' }}>
        {
          newValue !== undefined && !newValue ? <></> : <DrawerHeader />
        }
        {
          window.location.pathname === '/' ?
            <main className={classes.content} style={{ backgroundColor: '#fff', padding: newValue !== undefined && !newValue ? '0px' : '24px' }}>
              <div className={classes.toolbar} />
              {
                authState === "signedIn" && !isModalOpen ? (
                  <>
                    {
                      newValue !== undefined && !newValue ? (
                        <>
                          <ProfileSetup udpateValue={() => setNewValue(true)} />
                        </>
                      ) : (
                        <div>
                          <Grid container>
                            <AuthDisplay />
                            <Grid style={{ width: '100%', textAlign: 'center' }}>
                              {addDevice ? (
                                <VisualViewport>

                                  {isLoading ? (
                                    <CircularProgress />
                                  ) : (
                                    <Box
                                      component="form"
                                      noValidate
                                      onSubmit={handleSubmit(onSubmit)}
                                    >
                                      <Grid container spacing={2} className={classes.addQueue}>
                                        <Grid
                                          item
                                          xs={12}
                                          md={6}
                                          style={{ textAlign: "center" }}
                                        >
                                          {/* <DeviceIcon
                                            color="primary"
                                            className={classes.deviceIcon}
                                          /> */}
                                          <img src={qmdImage} alt="QR code" style={{ width: 'inherit' }} />

                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                          <b style={{ fontWeight: 1000 }}>
                                            Device Informations
                                          </b>
                                          <p className={classes.pModel}>
                                            Please provide details to identify your device.
                                          </p>
                                          <div>
                                            <Controller
                                              name="queueName"
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "Queue name cannot be empty",
                                                pattern: {
                                                  value: /^[a-z0-9]+$/i,
                                                  message:
                                                    "Name cannot contain spaces or special characters!",
                                                },
                                              }}
                                              render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                              }) => (
                                                <TextField
                                                  className={classes.textField}
                                                  value={value}
                                                  placeholder={"Queue Name "}
                                                  onChange={onChange}
                                                  error={!!error}
                                                  helperText={error ? error.message : " "}
                                                  InputProps={{
                                                    disableUnderline: true,
                                                    className: error
                                                      ? classes.inputError
                                                      : "",
                                                  }}
                                                  required
                                                />
                                              )}
                                            />
                                          </div>
                                          <div>
                                            <Controller
                                              name="deviceName"
                                              control={control}
                                              defaultValue=""
                                              rules={{
                                                required: "Device name cannot be empty",
                                                pattern: {
                                                  value: /^[a-z0-9]+$/i,
                                                  message:
                                                    "Name cannot contain spaces or special characters!",
                                                },
                                              }}
                                              render={({
                                                field: { onChange, value },
                                                fieldState: { error },
                                              }) => (
                                                <TextField
                                                  className={classes.textField}
                                                  placeholder={"Device Name "}
                                                  onChange={onChange}
                                                  error={!!error}
                                                  helperText={error ? error.message : " "}
                                                  value={value}
                                                  autoComplete={false}
                                                  InputProps={{
                                                    disableUnderline: true,
                                                    className: error
                                                      ? classes.inputError
                                                      : "",
                                                  }}
                                                  required
                                                />
                                              )}
                                            />
                                          </div>
                                          <div>
                                            {alert.message && (
                                              <span className={classes.warning}>
                                                {alert.message}
                                              </span>
                                            )}
                                          </div>
                                          <div className={classes.checkBox}>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={withDevice}
                                                  onChange={handleCheckChange}
                                                  className={classes.checkBox}
                                                />
                                              }
                                              label="I want to connect with QM Device"
                                            />
                                          </div>
                                          {showDeviceError && (
                                            <span className={classes.warning}>
                                              {showDeviceError}
                                            </span>
                                          )}

                                          <div>
                                            <Button variant="contained" type="submit" style={{ backgroundColor: '#060543' }}>
                                              Add Now
                                              {isLoadingSpinner ? <LoadingSpinner /> : null}
                                            </Button>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  )}
                                </VisualViewport>
                              ) : (
                                <div style={{ margin: "12px" }}>
                                  {!isLoading ? (
                                    deviceList && deviceList.length ? (
                                      <>
                                        <Grid container spacing={2}>
                                          {deviceList.map((list, index) => {
                                            const device = list.split("_")[1];
                                            const queue =
                                              list.split("_")[2] &&
                                              list
                                                .split("_")[2]
                                                .substring(
                                                  0,
                                                  list.split("_")[2].indexOf(".")
                                                );
                                            const checkId = list.replace(".json", "");
                                            return (
                                              <Grid item xs={12} md={6} lg={4} sm={6}>
                                                <ListItem
                                                  className={classes.listName}
                                                  component="div"
                                                  key={index}
                                                  style={{ cursor: 'pointer' }}
                                                  onClick={(e) => toggleDrawer1(e, checkId)}
                                                >
                                                  <ListItemIcon
                                                    onClick={(e) => toggleDrawer1(e, checkId)}
                                                    style={{
                                                      borderRight: "1px solid #D8D8D8",
                                                    }}
                                                  >
                                                    {/* <DeviceIcon
                                                      color="primary"
                                                      style={{
                                                        height: "90px",
                                                        marginRight: "20px",
                                                      }}
                                                    /> */}

                                                    <img src={qmdImage} alt="QR code" style={{ height: '80px', width: '140px' }} />

                                                  </ListItemIcon>
                                                  <ListItemText
                                                    primary={queue}
                                                    onClick={(e) => toggleDrawer1(e, checkId)}
                                                    style={{ color: '#000' }}
                                                  ></ListItemText>
                                                </ListItem>
                                                {/* </div> */}
                                              </Grid>
                                            );
                                          })}
                                          <Drawer1
                                            className={classes.drawer}
                                            variant={"temporary"}
                                            classes={{
                                              paper: classes.drawerPaper,
                                            }}
                                            anchor="right"
                                            open={openDrawer}
                                            onClose={handleCloseDrawer}
                                          >
                                            <div className={classes.toolbar} />
                                            <Home newId={newId} id={token} currentNumber={currentNumber} setCurrentNumber={setCurrentNumber} totalCount={totalCount} setTotalNumber={setTotalNumber} getUserDetails={getUserDetails} handleCloseDrawer={handleCloseDrawer} setOpenDrawer={setOpenDrawer} />
                                          </Drawer1>
                                        </Grid>
                                        {!addDevice && (
                                          <Grid
                                            item
                                            xs={1}
                                            className={classes.addBtn}
                                            style={{ position: 'fixed', bottom: '50px', right: '20px' }}
                                          >
                                            <Tooltip title='Add Queue'>
                                              <Fab
                                                color="primary"
                                                aria-label="add"
                                                onClick={handleAddDevice}
                                              >
                                                <AddIcon />
                                              </Fab>
                                            </Tooltip>
                                          </Grid>
                                        )}
                                      </>
                                    ) : (
                                      <Box className={classes.noQueue}>
                                        <ReactSVG src={queueNotFound} />
                                        <p>No Queue Found</p>
                                        <Grid item xs={12} style={{ marginTop: 50, marginBottom: 30 }}>
                                          <div>
                                            <Button style={{ backgroundColor: "rgb(6, 5, 67)", color: "white", borderRadius: "7px" }} type="submit" onClick={handleAddDevice} >
                                              Add Queue
                                            </Button>
                                          </div>
                                        </Grid>
                                      </Box>
                                    )
                                  ) : (
                                    <CircularProgress
                                      className={classes.queueLoader}
                                      color="primary"
                                    />
                                  )}
                                </div>
                              )}
                            </Grid>
                          </Grid>
                          <CModal open={isOpen} handleClose={handleClose}>
                            <div>
                              <Box>
                                <img src={qrScan} alt="QR code" />
                              </Box>
                              <p>Please check internet connection then reload page</p>
                            </div>
                          </CModal>
                          <Modal
                            open={openImgName}
                            onClose={handleCloseImgName}
                            disableBackdropClick={true}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box
                              className={
                                classes.modal + " " + profileClasses.changePassword
                              }
                            >
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                                className={profileClasses.modalHeader}
                              >
                                Enter Details
                              </Typography>
                              <img
                                src={image}
                                // alt="prifile_pic"
                                name="file"
                                className="img-thumbnail"
                                height={100}
                                width={100}
                              />
                              <Box>
                                <label htmlFor="icon-button-file">
                                  <Input
                                    style={{ display: "none" }}
                                    className="form-control"
                                    name="file"
                                    accept="image/*"
                                    id="icon-button-file"
                                    type="file"
                                    onChange={handleUploadImg}
                                  />
                                  <IconButton
                                    color="primary"
                                    aria-label="upload picture"
                                    component="span"
                                    onClick={() => setCheck(!check)}
                                  >
                                    <PhotoCamera />
                                  </IconButton>
                                </label>
                              </Box>
                              <TextField
                                fullWidth
                                className={profileClasses.modalBody}
                                required
                                placeholder="Enter Name here..."
                                value={userNameNew}
                                onChange={(e) => {
                                  setUserNameNew(e.target.value);
                                }}
                              />
                              <Box className={profileClasses.modalFooter}>
                                <Button
                                  variant="contained"
                                  color="success"
                                  size="medium"
                                  onClick={saveImageAndUserName}
                                  disabled={!userNameNew && check}
                                >
                                  Submit
                                </Button>
                              </Box>
                            </Box>
                          </Modal>
                        </div>
                      )
                    }
                  </>
                ) : authState === "signedIn" && isModalOpen ? (
                  <>
                    <ProfileSetup udpateValue={() => setNewValue(true)} />
                  </>
                ) : null
              }
            </main> : <></>
        }
        {
          window.location.pathname !== '/' ?
            <main className={classes.content} style={{ backgroundColor: '#fff', height: 'calc(100% - 64px)' }}>
              <div className={classes.toolbar} />
              {props.children}
            </main> : <></>
        }
      </Box>
    </Box>
  ) : <></>
}
