import React, { Children } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import {
  Analytics, HomePage, LoginAmplify, TokenRegistration,
  UserSignUp, WifiList, RegistrationSuccess, Sms
} from "./pages";
import {
  BillBoardScreen, Device, Setting, SettingDetails,
  EditProfile, VoiceMessage, VoiceMessageDetails,
  CustomForm, CustomFormDetails
} from "./component";
import PrivateRoute from "./services/PrivateRoute";
import CContextProvider from "./component/auth-components/CContext";
import ValidateUserRoute from "./component/Router/ValidateUserRoute";
import ErrorBoundary from "./component/PageNotFound";
import MainLayout from "./pages/MainLayout";
import ChangePassword from "./component/ChangePassword";
import MyProfile from "./component/MyProfile";
import LandingPage from "./component/LandingPage";

function App() {
  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(false);

  React.useEffect(() => {
    const authState = localStorage.getItem("amplify-authenticator-authState");
    setIsUserLoggedIn(authState === "signIn");
  }, []);

  return (
    <ErrorBoundary>
      <CContextProvider>
        <Router>
          <Switch>
            {/* Public Routes */}
            {/* <Route exact path="/" component={LandingPage} />  */}
            <Route exact path="/" component={LoginAmplify} />
            <Route exact path="/token/:id" component={TokenRegistration} />
            <Route exact path="/RegistrationSuccess/:id" component={RegistrationSuccess} />

            {/* Private Routes with MainLayout */}
            <MainLayout>
              <Switch>
                <PrivateRoute exact path="/sms" component={Sms} />
                <PrivateRoute exact path="/tr/:device" component={UserSignUp} />
                <ValidateUserRoute path="/analytics/:id" component={Analytics} />
                <ValidateUserRoute path="/queue/:id" component={HomePage} />
                <ValidateUserRoute exact path="/device-setting" component={Device} />
                <ValidateUserRoute path="/device-setting/:id" component={SettingDetails} />
                <ValidateUserRoute path="/device/:id" component={WifiList} />
                <ValidateUserRoute path="/bill-board" component={BillBoardScreen} />
                <ValidateUserRoute path="/setting" component={Setting} />
                <ValidateUserRoute path="/edit-profile" component={EditProfile} />
                <ValidateUserRoute path="/voice-message" component={VoiceMessage} />
                <ValidateUserRoute path="/message-details/:id" component={VoiceMessageDetails} />
                <ValidateUserRoute path="/custom-form" component={CustomForm} />
                <ValidateUserRoute path="/custom-details/:id" component={CustomFormDetails} />
                <ValidateUserRoute path="/change-password" component={ChangePassword} />
                <ValidateUserRoute path="/my-profile" component={MyProfile} />
              </Switch>

            </MainLayout>


            {/* Fallback Route */}
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        </Router>
      </CContextProvider>
    </ErrorBoundary>
  );
}

export default App;
