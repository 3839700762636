import { Typography, Grid, Button } from "@material-ui/core";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import QeaseLogo from "../assests/images/QeaseLogo.png";
import GroupPeople from "../assests/images/GroupPeople.png";

// import useStyles from "../../assests/css/authStyles";
// import GroupIcon from "../../assests/images/Group.png";

import useStyles from "../assests/css/SignInCss";
import GroupIcon from "../assests/images/Group.png";
import defaultImage from "../assests/images/default-profile.png";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { CModal, CroppieModal } from "../component/";
import CButton from "../component/auth-components/CButton";
import { useForm, Controller } from "react-hook-form";
import { Box } from "@mui/system";
import CAlert from "../component/CAlert";
import { uploadImageName } from "../api";
import { CContext } from "../component/auth-components/CContext";
import ImageCropper from "../component/ImageCropper";

const bckStyles = {
  paperContainer: {
    height: 1356,
    backgroundImage: `url(${"../assests/images/Group.svg"})`,
  },
};
const ProfileSetup = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [alert, setAlert] = useState({ message: "", type: "" });
  const { reset, handleSubmit, control } = useForm();
  const [imageToCrop, setImageToCrop] = React.useState(undefined);
  const { authState } = props;

  const { udpateValue } = props;

  const classes = useStyles();
  // const handleClickShowPassword = () => setShowPassword(!showPassword);
  // const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [nameValidationError, setnameValidationError] = useState("");
  // const [phoneValidationError, setPhoneValidationError] = useState("");

  const [userNameNew, setUserNameNew] = React.useState("");
  // const [phone, setPhone] = React.useState("");
  // const [imageToupload, setimageToupload] = React.useState("");
  const [img, setImg] = React.useState("");
  const [imageUpload, setImageUpload] = React.useState(false);
  const [selectedImg, setSelectedImg] = React.useState(null);
  const [showImageError, setImageShowError] = React.useState(false);
  const [croppedImage, setCroppedImage] = React.useState("");
  const [showError, setShowError] = React.useState(false);

  const {
    isModalOpen,
    userNameFromContext,
    setuserNameFromContext,
    setisModalOpen,
  } = React.useContext(CContext);

  const handleCloseImageUpload = () => {
    setImageUpload(false);
  };

  const uploadImage = (base64) => {
    // if (selectedImg.size > 2500000) {
    //   handleCloseImageUpload();
    //   return setImageShowError("Image should be only max 2MB");
    // }
    setImageShowError(false);
    setImg(base64);
    localStorage.setItem("img", base64);
    localStorage.setItem(
      `${userNameFromContext.split("@")[0].replace(".", "")}_img`,
      base64
    );
    // saveImageAndUserName(userNameNew, base64.split(",")[1]);
    handleCloseImageUpload();
    // convertLength

    // getBase64(base64).then((base64) => {
    //   setImg(base64);
    //   console.log("base64.split(", ")[1]", base64);
    //   localStorage.setItem("img", base64);
    //   saveImageAndUserName(userNameNew, base64.split(",")[1]);
    //   handleCloseImageUpload();
    // });
  };

  const saveNameImageApiCall = () => {
    // const validEmail = /^[a-zA-Z ]*$/;
    // // var result = regex.test(str);
    // console.log("saveNameImageApiCall1");
    if (userNameNew == "") {
      // setAlert({ message: "Please enter Name", type: "error" });
      // setTimeout(() => {
      //   setAlert({ message: "", type: "" });
      // }, 2500);
      // setnameValidationError("Please enter Name");
      return;
    }
    // if (!validEmail.test(userNameNew)) {
    //   setnameValidationError("Only Character allowed");
    //   return;
    // }
    else if (img == "") {
      setAlert({ message: "Please Select Image", type: "error" });
      setTimeout(() => {
        setAlert({ message: "", type: "" });
      }, 2500);
      setShowError("Please Select Image");
      setTimeout(() => {
        setShowError("");
      }, 2000);
      // setnameValidationError("Please Select Image");
      return;
    }

    // console.log(userNameNew);
    // console.log("userNameFromContext.split("@")[0] ");
    console.log("imguploadImageName4", img);

    saveImageAndUserName(
      userNameFromContext.split("@")[0] + "_" + userNameNew,
      img
    );
    // setUserNameNew("");
    // setImg("");
    // setisModalOpen(false);
  };
  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();

      reader.addEventListener("load", () => {
        const image = reader.result;

        setImageToCrop(image);
        console.log("uploadImage", image);
      });

      reader.readAsDataURL(event.target.files[0]);
    }
  };
  // const saveImageAndUserName = async (name, image) => {
  //   // console.log("saveNameImageApiCall3");

  //   // console.log("uploadImageNameData", name, image);
  //   const out = await uploadImageName(name, image);
  //   if (out.status === 200) {
  //     // console.log("saveNameImageApiCall4");

  //     setisModalOpen(false);
  //     setUserNameNew("");
  //     setImg("");
  //     localStorage.setItem("IsProfileSettingUP", false);
  //     udpateValue();
  //     // window.location.reload(false);
  //   }
  //   // console.log("out", out.status);
  // };

  function reduceImageSize(base64Image, callback) {
    const img = new Image();
    img.src = "data:image/jpeg;base64," + base64Image;

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const maxWidth = 1024; // You can adjust this as needed
      const maxHeight = 1024; // You can adjust this as needed

      let width = img.width;
      let height = img.height;

      // Resize the image while preserving its aspect ratio
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      // Convert the canvas to a base64 image
      const resizedImage = canvas.toDataURL("image/jpeg");

      callback(resizedImage);
    };
  }

  const saveImageAndUserName = async (name, image) => {
    console.log("imguploadImageName1", image, name);
    // Create a Blob from the base64 image data
    const imageData = image.split(",");
    const sizeInMB = (imageData[1].length * 3) / 4 / 1024 / 1024; // Calculate image size in MB
    if (sizeInMB > 2.5) {
      reduceImageSize(imageData[1], async (resizedImage) => {
        // Mark this function as async
        // Handle the resized image (e.g., save or upload)
        // You can replace the following code with your logic
        console.log("imguploadImageName2", name, resizedImage);
        const out = await uploadImageName(name, resizedImage);

        if (out.status === 200) {
          setisModalOpen(false);
          setUserNameNew("");
          setImg("");
          localStorage.setItem("IsProfileSettingUP", false);
          udpateValue();
        }
      });
    } else {
      // Upload the original image
      console.log("imguploadImageName3", name, image);
      const out = await uploadImageName(name, image.split(",")[1]);
      console.log('dscdscwee', out)
      if (out.status === 200) {
        setisModalOpen(false);
        setUserNameNew("");
        setImg("");
        localStorage.setItem("IsProfileSettingUP", false);
        udpateValue();
      }
    }
  };
  // const base64Data = atob(image);
  // const arrayBuffer = new ArrayBuffer(base64Data.length);
  // const view = new Uint8Array(arrayBuffer);
  // for (let i = 0; i < base64Data.length; i++) {
  //   view[i] = base64Data.charCodeAt(i);
  // }
  // const blob = new Blob([arrayBuffer], { type: "image/jpeg" });

  // // Check if the image size is greater than 2.5MB
  // if (blob.size > 2500000) {
  //   // Resize the image
  //   const resizedImage = await resizeImage(blob, 2500000); // Resize to a max size of 2.5MB

  //   // Upload the resized image
  //   console.log("imguploadImageName", name, resizedImage);
  //   const out = await uploadImageName(name, resizedImage);

  //   if (out.status === 200) {
  //     setisModalOpen(false);
  //     setUserNameNew("");
  //     setImg("");
  //     // localStorage.setItem("IsProfileSettingUP", false);
  //     udpateValue();
  //   }
  // } else {
  //   // Upload the original image
  //   console.log("imguploadImageName", name, blob);
  //   const out = await uploadImageName(name, blob);

  //   if (out.status === 200) {
  //     setisModalOpen(false);
  //     setUserNameNew("");
  //     setImg("");
  //     // localStorage.setItem("IsProfileSettingUP", false);
  //     udpateValue();
  //   }
  // }
  function resizeImage(blob, maxSize) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
            }
          } else {
            if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          canvas.toBlob((resizedBlob) => {
            resolve(resizedBlob);
          }, "image/jpeg");
        };
      };
    });
  }
  const handleImg = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        // if (file.size > 2500000) {
        //   return setImageShowError("Image should be only max 2MB");
        // }
        setImageUpload(true);
        setImageShowError(false);
        setSelectedImg(file);
        onUploadFile(e);
      } else {
        return setImageShowError("Only images are allow");
      }
    }
  };

  useEffect(() => {
    reset();
    // localStorage.setItem("IsProfileSettingUP", true);
    setAlert({ message: "", type: "" });
  }, [authState]);

  const closeAlert = () => {
    setAlert({ message: "", type: "" });
  };

  const onSubmit = async (data) => {
    // setIsDisabled(true);
    // try {
    //   await Auth.signIn(data.email, data.password);
    //   // onStateChange("signedIn");
    //   setIsDisabled(false);
    // } catch (error) {
    //   console.log("error.message", error.message);
    //   setAlert({ message: error.message, type: "error" });
    //   setIsDisabled(false);
    // }
  };

  return (
    <Box
      component="form"
      noValidate
      className={classes.root}
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* {alert.message !== "" && (
        <CAlert message={alert.message} type={alert.type} setAlert={setAlert} />
      )} */}

      <Grid
        container
        className={classes.containerHeight}
        style={{
          // margin: "auto",
          // padding: "20px 1.2rem",
          backgroundImage: `url(${GroupIcon})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        {/* <Grid
          item
          xs={12}
          sm={12}
          md={6}
          className={classes.ImagebackgroundColor}
          alignItems="center"
          justifyContent="center"
          style={{ display: "flex" }}
        > */}
        {/* <Item> */}
        {/* <div style={{ position: "relative" }}> */}

        {/* <Typography className={classes.PageSectionHeading}>
            Profilesds
          </Typography> */}
        {/* <Group className={classes.imageStyle} /> */}
        {/* <img
            src={GroupIcon}
            style={{ width: "75%" }}
            className={classes.imageStyle}
            alt="fireSpot"
          /> */}

        {/* </div> */}

        {/* </Item> */}
        {/* </Grid> */}
        <img src={GroupPeople} />
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
        //   alignItems="center"
        //   justifyContent="center"
        >
          {/* <Item> */}

          <div className={classes.signInContainer} style={{ margin: "auto" }}>
            {/* <Typography className={classes.signInHeader} align="center">
          Welcome to Queue Management
        </Typography> */}
            <div className={classes.signIn}>
              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  display: "flex",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <img
                  src={QeaseLogo}
                  style={{ width: "45%" }}

                // alt="fireSpot"
                />
              </div>
              <Typography className={classes.HeadingText}>Profile</Typography>
              <Box
                className={classes.upperProfile}
                style={{
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <img
                  style={{ borderRadius: "50%" }}
                  src={
                    img != null && img != undefined && img != ""
                      ? img
                      : defaultImage
                  }
                  key={img}
                  name="file"
                  className="img-thumbnail"
                  height={100}
                  width={100}
                  alt="profile"
                />
                <input
                  style={{ display: "none" }}
                  id="selectImage"
                  type="file"
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  onChange={handleImg}
                  accept="image/x-png,image/gif,image/jpeg"
                  className={classes.input}
                />
                <Box sx={{ height: "10px" }} />
                <label className={classes.icon} htmlFor="selectImage">
                  {<PhotoCamera />}
                </label>
                {showImageError && (
                  <span style={{ color: "red" }}>{showImageError}</span>
                )}
                <CModal
                  maxWidth="xs"
                  open={imageUpload}
                  handleClose={handleCloseImageUpload}
                  bgClick={true}
                >
                  {/* {console.log("selectedImg", selectedImg)} */}
                  <ImageCropper
                    imageToCrop={imageToCrop}
                    onImageCropped={(croppedImage) =>
                      setCroppedImage(croppedImage)
                    }
                  />
                  {/* <CroppieModal
                    image={selectedImg}
                    uploadImage={uploadImage}
                    uploading={false}
                    closeModal={handleCloseImageUpload}
                    modalTitle={"Changeadadadsaprofile picture"}
                    error={showImageError}
                    setImage={setCroppedImage}
                  /> */}
                  <Grid container>
                    <Grid item xs={6} align={"left"}>
                      <div className={classes.cancelBtn}>
                        <Button
                          variant="outlined"
                          onClick={handleCloseImageUpload}
                        >
                          Cancel
                        </Button>
                      </div>
                    </Grid>
                    {/* {console.log("croppedImage", croppedImage)} */}

                    <Grid item xs={6} align={"right"}>
                      <div className={classes.uploadBtn}>
                        <Button
                          variant="outlined"
                          disabled={!selectedImg && !showImageError}
                          onClick={() => uploadImage(croppedImage)}
                        >
                          Upload
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </CModal>
              </Box>
              <Box sx={{ height: "20px" }} />
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: "Name cannot be empty",
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    id="outlined-error-helper-text"
                    label="Your Name"
                    value={userNameNew}
                    onChange={(e) => {
                      setnameValidationError("");
                      setUserNameNew(e.target.value);
                      onChange(e);
                    }}
                    variant="outlined"
                    placeholder="Your Name"
                    helperText={error ? error.message : " "}
                    error={!!error}
                  />
                )}
              />
              {/* <Box sx={{ height: "10px" }} /> */}
              {/* <Controller
                name="phone"
                control={control}
                defaultValue=""
                rules={{
                    required: {
                      value: true,
                      message: "Phone Number is Required",
                    },
                    minLength: {
                      value: 10,
                      message: "Please enter a valid 10 digit number",
                    },
                    pattern: {
                      value: /^(?:91?|[0]?)?[6789]\d{9}$/,
                      message: "Invalid mobile number",
                    },
                  }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                 
                  <TextField
                    id="outlined-error-helper-text"
                    label="Your Phone"
                    
                    value={phone}
                    onChange={(e) => {
                      setPhoneValidationError("");
                      setPhone(e.target.value);
                      onChange(e);
                    }}
                    minLength="10"
                    variant="outlined"
                    
                    placeholder="Your Phone"
                    helperText={error ? error.message : " "}
                    error={!!error}
                  />
                )}
              /> */}
              {showError ? (
                <div style={{ marginLeft: "10px" }}>
                  <span style={{ color: "red", fontSize: "small" }}>
                    {showError}
                  </span>
                </div>
              ) : (
                <span style={{ visibility: "hidden" }}>Placeholder</span>
              )}
              <Box sx={{ width: "100%" }} margin="auto">
                <CButton
                  type="submit"
                  onClick={saveNameImageApiCall}
                  variant="contained"
                  disabled={isDisabled}
                // style={{ width: "80%" }}
                >
                  Save
                </CButton>
              </Box>
            </div>
            {/* <Typography className={classes.signInFooter} align="center">
          Not a member?{" "}
          <span
            onClick={() => {
              setAlert({ message: "", type: "" });
              onStateChange("signUp");
            }}
          >
            Register Now
          </span>
        </Typography> */}
          </div>
          {/* </Item> */}
        </Grid>
      </Grid>
    </Box>
  );
};
export default ProfileSetup;
