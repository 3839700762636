import React, { useState, useEffect } from "react";
import { Box, Button, Grid, InputAdornment, Typography, TextField, Modal, CircularProgress, Divider } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CheckIfPasswordIsValid } from "../services/utils";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import useStyles from "../assests/css/profileStyle";
import Habilelabs from '../assests/images/habilelabs-squareLogo.png'


const ChangePassword = () => {
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [oldPasswordShow, setOldPasswordShow] = useState(false);
    const [newPasswordShow, setNewPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const [showError, setShowError] = useState("");

    const history = useHistory();
    const classes = useStyles();
    const handleChange = (e, type) => {
        if (type === "old") setOldPassword(e.target.value);
        if (type === "new") setNewPassword(e.target.value);

        if (type === "confirm") setConfirmPassword(e.target.value);
        setShowError(false);
    };

    const handleCloseChangePassword = () => {
        setOpenChangePassword(false);
        setShowError(false);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
    };
    const signOutBtn = () => {
        localStorage.clear();
        window.location.reload();
    };
    const handleChangePassword = () => {
        const isValid = CheckIfPasswordIsValid(
            oldPassword,
            newPassword,
            confirmPassword
        );
        if (isValid) return setShowError(isValid);
        Auth.currentAuthenticatedUser()
            .then((user) => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then((data) => {
                if (data === "SUCCESS") {
                    signOutBtn();
                    history.push("/");
                    setOldPassword("");
                    setNewPassword("");
                    setConfirmPassword("");
                    handleCloseChangePassword();
                } else {
                    setShowError("Old password not matched!");
                }
            })
            .catch((err) => setShowError("Old password not matched!"));
    };

    return (
        <Grid container className={classes.main} style={{ height: '100%' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.profile}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '100%',
                        zIndex: 1000,
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#fff',
                            padding: '20px',
                            borderRadius: '10px',
                            width: '100%',
                            maxWidth: '600px',
                            boxShadow: '1px 1px 26px rgba(132, 129, 138, 0.30)',
                        }}
                    >
                        <div style={{ marginBottom: '20px', textAlign: 'center' }}>
                            <img
                                src={Habilelabs}
                                name="file"
                                className="img-thumbnail"
                                height={50}
                                width={50}
                                style={{ border: '1px solid gray', borderRadius: '50%', }}
                                alt="profile"
                            />
                        </div>
                        <Typography style={{ marginBottom: '20px' }}>Change Login Password</Typography>
                        <div
                            style={{ flexDirection: "row", display: "flex", height: '3rem' }}
                        >
                            <TextField
                                fullWidth
                                placeholder="Old Password"
                                type={oldPasswordShow ? "text" : "password"}
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {oldPasswordShow ? (
                                                <Visibility onClick={() => setOldPasswordShow(!oldPasswordShow)} />
                                            ) : (
                                                <VisibilityOff onClick={() => setOldPasswordShow(!oldPasswordShow)} />
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div
                            style={{ flexDirection: "row", display: "flex", height: '3rem' }}
                        >
                            <TextField
                                fullWidth
                                placeholder={"New Password "}
                                type={newPasswordShow ? "text" : "password"}
                                autoComplete={false}
                                required
                                value={newPassword}
                                onChange={(e) => handleChange(e, "new")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {newPasswordShow ? (
                                                <Visibility
                                                    style={{ color: "black" }}
                                                    onClick={() =>
                                                        setNewPasswordShow(!newPasswordShow)
                                                    }
                                                />
                                            ) : (
                                                <VisibilityOff
                                                    style={{ color: "black" }}
                                                    onClick={() =>
                                                        setNewPasswordShow(!newPasswordShow)
                                                    }
                                                />
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        <div
                            style={{ flexDirection: "row", display: "flex", height: '3rem' }}
                        >
                            <TextField
                                fullWidth
                                placeholder={"Confirm Password "}
                                type={confirmPasswordShow ? "text" : "password"}
                                autoComplete={false}
                                required
                                value={confirmPassword}
                                onChange={(e) => handleChange(e, "confirm")}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {confirmPasswordShow ? (
                                                <Visibility
                                                    style={{ color: "black" }}
                                                    onClick={() =>
                                                        setConfirmPasswordShow(
                                                            !confirmPasswordShow
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <VisibilityOff
                                                    style={{ color: "black" }}
                                                    onClick={() =>
                                                        setConfirmPasswordShow(
                                                            !confirmPasswordShow
                                                        )
                                                    }
                                                />
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                        {showError && (
                            <span style={{ color: "red" }}>{showError}</span>
                        )}
                        <div
                            className={classes.modalFooter1}
                            style={{
                                marginTop: '20px',
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <Button
                                variant="text"
                                size="medium"
                                onClick={handleCloseChangePassword}
                                style={{ backgroundColor: "#FFF", margin: "13px", color: "#000", border: '1px solid gray' }} >
                                Cancel
                            </Button>
                            <Button variant="text"
                                size="medium"
                                onClick={handleChangePassword}
                                style={{ backgroundColor: "#060543", margin: "13px", color: "white" }}>
                                Save
                            </Button>
                        </div>
                    </Box>
                </div>
            </Grid>
        </Grid>
    )
};

export default ChangePassword;