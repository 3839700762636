import { httpservice } from "../services/httpservice";
import { durationTimetoMinutes } from "../services/utils";
const moment = require("moment");
var axios = require("axios");

export const getCurrentCount = (device, DateTime) => {
  // console.log("getCurrentCount")

  return httpservice()
    .post(`${process.env.REACT_APP_storingdata}/count`, {
      TableName: "QMS_Device",
      Data: "",
      Find: `${device.replace(/[.-]/g, "")}_Device`,
      DateTime,
    })
    .then(function (response) {
      // console.log("response", response);
      const updatedResponse = {
        currentNum: 0,
        appointmentTime: 0,
        duration: 5,
      };
      if (
        response.data &&
        response.data.Data &&
        Object.keys(response.data && response.data.Data).length
      ) {
        let date = response.data.Data.Date_Time.split("/")[0];
        if (
          date.toString().trim() ==
          moment(new Date()).format("YYYY-MM-DD").toString().trim()
        ) {
          updatedResponse.currentNum = Number(response.data.Data.Count);
          updatedResponse.appointmentTime = response.data.Data.Your_Time;
          updatedResponse.yourNumber = Number(response.data.Data.Your_Number);
          updatedResponse.duration =
            response.data.Data.Duration &&
            durationTimetoMinutes(response.data.Data.Duration);
          return updatedResponse;
        } else {
          return updatedResponse;
        }
      } else {
        return updatedResponse;
      }
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};

export const getTotalCount = (device) => {
  return httpservice()
    .post(`${process.env.REACT_APP_storingdata}/count`, {
      TableName: "QMS_Device",
      Data: "",
      Find: `${device.replace(/[.-]/g, "")}_App`,
    })
    .then(function (response) {
      let offlineNo = { Count: 0 };
      const timeStamp = moment(new Date()).format("YYYY-MM-DD");
      if (response.data && response.data.Data.Count) {
        if (response.data.Data.Date_Time.split("/")[0].trim() === timeStamp) {
          offlineNo = response.data.Data;
          return offlineNo;
        } else return offlineNo;
      } else {
        return offlineNo;
      }
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};

// export const sendUserDetails = (data) => {
//   // console.log("data==_-----------", data);
//   return httpservice()
//     .post(`${process.env.REACT_APP_storingdata}/bookAppointment`, {
//       TableName: "QMS_Device",
//       Data: `[${data.deviceName.replace(/[.-]/g, "")}_App,${data.date},${
//         data.name
//       }, ${data.contact}]`,
//       Find: "",
//       Notification_Id: data.notificationId, //Device details goes here
//     })
//     .then(function (response) {
//       return response.data;
//     })
//     .catch(function (error) {
//       return error.response && error.response.data
//         ? error.response.data
//         : error;
//     });
// };
export const sendUserDetailsFormData = (data) => {
  // console.log("data==_-----------", data);
  return httpservice()
    .post(`${process.env.REACT_APP_storingdata}/bookAppointment`, {
      TableName: "QMS_Device",
      Data: `[${data.deviceName.replace(/[.-]/g, "")}_App,${data.date},${
        data.formData.name
      }, ${data.formData.contact}]`,
      FormData: data.formData,
      Find: "",
      Notification_Id: data.notificationId, //Device details goes here
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};
export const sendUserDetailsToSelectSlot = (data) => {
  // console.log("data==_-----------", data);
  return httpservice()
    .put(`${process.env.REACT_APP_items}`, {
      Device_Name: `${data.deviceName}_App`,
      Time_Slot: data.timeSlot,
      Name: data.name,
      Contact: data.contact,
      Notification_Id: "",
    })
    .then(function (response) {
      // console.log("sendUserDetailsToSelectSlot", response);
      return response.data;
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};
export const sendUserDetailsToConfirmSlot = (data) => {
  // console.log("data==_-----------", data);
  return httpservice()
    .post(`${process.env.REACT_APP_items}`, {
      Device_Name: `${data.deviceName}_App`,
      Time_Slot: data.timeSlot,
      Name: data.formData.name,
      Contact: data.formData.contact,
      Notification_Id: data.notificationId,
      FormData: data.formData,
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};

export const getSlotDetail = (data) => {
  // console.log("data==_-----------", data);
  return httpservice()
    .put(`${process.env.REACT_APP_items}`, {
      Device_Name: `${data.deviceName}_App`,
      Time_Slot: data.timeSlot,
      Name: data.name,
      Contact: data.contact,
      Notification_Id: "",
    })
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};

// export const getConnectedStatus = (device) => {
//   return httpservice()
//     .post(`${process.env.REACT_APP_storingdata}`, {
//       TableName: "QMS_Device",
//       Data: "",
//       Find: `${device.replace(/[.-]/g, "")}_connect`,
//     })
//     .then(function (response) {
//       let isConnected = "";
//       if (response.data && response.data.Data) {
//         isConnected = response.data.Data.Connection;
//         return isConnected;
//       } else {
//         isConnected = "disconnected";
//         return isConnected;
//       }
//     })
//     .catch(function (error) {
//       return error.response && error.response.data
//         ? error.response.data
//         : error;
//     });
// };

export const createQueue = (device) => {
  return httpservice()
    .post(`${process.env.REACT_APP_queuecreation}/createQueue`, {
      Device: device.device.replace(/[.-]/g, ""),
      Action: "create",
      Device_Type: "QMD",
    })
    .then(function (response) {
      // console.log("response", response);
      return response;
    })
    .catch(function (error) {
      // console.log("createQueue");
      // console.log(error.response.data);
      // console.log(error.response.status);
      return error.response && error.response.data ? error.response : error;
    });
};

export const getDeviceAndQueueList = (user) => {
  // console.log("getDeviceAndQueueList",user)
  return httpservice()
    .post(`${process.env.REACT_APP_queuecreation}/getQueueList`, {
      Device: user.replace(/[.-]/g, ""),
      Action: "get_list",
      Device_Type: "QMD",
    })
    .then(function (response) {
      // console.log("getDeviceAndQueueList",response.data.files)
      return response.data && response.data.files;
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};

export const checkDeviceIP = () => {
  return httpservice()
    .get(`https://jsonplaceholder.typicode.com/todos/1`)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};

export const getDelayMessage = (deviceName, secondayKey) => {
  // console.log(
  //   "${process.env.REACT_APP_getDelayMessage}/${deviceName} :",
  //   ` ${process.env.REACT_APP_getDelayMessage}/${deviceName}`
  // );
  console.log("secondaryKey", secondayKey);

  var tempdata = JSON.stringify({
    key: secondayKey,
  });

  var config = {
    method: "post",
    url: `${process.env.REACT_APP_getDelayMessage}/${deviceName}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: tempdata,
  };
  console.log("secondaryKey", config);

  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      console.log("secondaryKey", response.data);
      return response;
    })
    .catch(function (error) {
      console.log(error);
    });

  // return httpservice()
  //   .get(`${process.env.REACT_APP_getDelayMessage}/${deviceName}`)
  //   .then(function (response) {
  //     // console.log(' delay    response :', response);
  //     return response;
  //   })
  //   .catch(function (error) {
  //     return "error";
  //   });
};

// export const sendDeviceWifiCred = (data) => {
//   return httpservice()
//     .get(`http://192.168.4.1/configure?${data}`)
//     .then(function (response) {
//       return response;
//     })
//     .catch(function (error) {
//       return error.response && error.response.data
//         ? error.response.data
//         : error;
//     });
// };

// export const sendDeviceTokenName = (data) => {
//   return httpservice()
//     .get(`http://192.168.4.1/token?${data}`)
//     .then(function (response) {
//       return response;
//     })
//     .catch(function (error) {
//       return error.response && error.response.data
//         ? error.response.data
//         : error;
//     });
// };

export const deleteQueue = (device) => {
  return httpservice()
    .post(`${process.env.REACT_APP_queuecreation}/deleteQueue`, {
      Device: device.replace(/[.-]/g, ""),
      Action: "delete",
      Device_Type: "QMD",
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};

export const getAnalyticList = (device, date) => {
  return httpservice()
    .post(`${process.env.REACT_APP_storingdata}/analytics`, {
      TableName: "QMS_Device",
      Data: "",
      Find: `${device}_App`,
      Action: "Analytics_people",
      Date: date,
    })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};

export const uploadImageName = (name, image) => {
  // console.log(`Name in Api Call: ${name}`);
  var data = JSON.stringify({
    Device_Type: "QMD",
    Action: "save_image",
    Device: name,
    Image: image,
  });

  var config = {
    method: "post",
    url: process.env.REACT_APP_uploadImageName,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  console.log('configconfig', config)
  return axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
      // console.log(
      //   "process.env.REACT_APP_uploadImageNameprocess.env.REACT_APP_uploadImageName"
      // );
      console.log('configconfig1', response)

      return response;
    })
    .catch(function (error) {
      // console.log(error);
      return error;
    });
  // return httpservice()
  //   .post(`${process.env.REACT_APP_REACT_APP_smart_bell_lambda}`, {
  //     Device_Type: "QMD",
  //     Action: "save_image",
  //     Device: name,
  //     Image: image,
  //   })
  //   .then(function (response) {
  //     console.log("response", response);
  //     return response;
  //   })
  //   .catch(function (error) {
  //     console.log("error", error);
  //     return error.response && error.response.data
  //       ? error.response.data
  //       : error;
  //   });
};

export const getUserImage = (userName) => {
  var data = JSON.stringify({
    Device_Type: "QMD",
    Action: "read_image",
    Device: userName,
  });

  var config = {
    method: "post",
    url: process.env.REACT_APP_getUserImage,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log("process.env.REACT_APP_getUserImage");
      // console.log("response", response);
      // console.log(JSON.stringify(response.data));
      return response.data;
    })
    .catch(function (error) {
      // console.log(error);
    });

  // return httpservice()
  //   .post(`${process.env.REACT_APP_REACT_APP_smart_bell_lambda}`, {
  //     Device_Type: "QMD",
  //     Action: "read_image",
  //     Device: userName,
  //   })
  //   .then(function (response) {
  //     return response;
  //   })
  //   .catch(function (error) {
  //     return error.response && error.response.data
  //       ? error.response.data
  //       : error;
  //   });
};
export const getDoctorFormPreference = (data) => {
  // console.log("getDoctorFormPreferencedata", data);
  // const data=data.replace(/[.-]/g, "")
  var apiData = JSON.stringify({
    Device_Name: data,
  });
  // console.log("datadata", data);
  // console.log("apiDataapiData", apiData);
  var config = {
    method: "PUT",
    // url: "https://kj1xxpgu1l.execute-api.eu-central-1.amazonaws.com/default/devuserpreference",
    url: `${process.env.REACT_APP_userPreference}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: apiData,
  };
  // console.log("here@23");
  return axios(config)
    .then(function (response) {
      // console.log("axiosResponse", response.data.data.Form_Field);
      // console.log("axiosResponse", JSON.stringify(response.data));
      // console.log("axiosResponse", JSON.stringify(response.message));
      return response.data.data.Form_Field;
    })
    .catch(function (error) {
      // console.log(error);
    });
};
export const getDoctorFormUserPreference = (data) => {
  // console.log("getDoctorFormPreferencedata", data);

  var apiData = JSON.stringify({
    Device_Name: data,
  });
  // console.log("datadata", data);
  // console.log("apiDataapiData", apiData);
  var config = {
    method: "PUT",
    url: `${process.env.REACT_APP_userPreference}`,
    // url: "https://kj1xxpgu1l.execute-api.eu-central-1.amazonaws.com/default/devuserpreference",

    headers: {
      "Content-Type": "application/json",
    },
    data: apiData,
  };
  console.log("here@23");
  return axios(config)
    .then(function (response) {
      // console.log("axiosResponse", response.data.data.Form_Field);
      // console.log("axiosResponse", JSON.stringify(response.data));
      // console.log("axiosResponse", JSON.stringify(response.message));
      return response.data;
    })
    .catch(function (error) {
      // console.log(error);
    });
};
export const IsVisitedApiHandler = (data) => {
  // console.log("IsVisitedApiHandlerdata", data);
  // console.log("IsVisitedApiHandlerdata", data.Device_Name.DeviceName);
  // console.log("IsVisitedApiHandlerdata", data.Date_Time);
  // console.log("IsVisitedApiHandlerdata", data.isVisited);
  var data = JSON.stringify({
    Device_Name: data.Device_Name.DeviceName,
    DateTime: data.Date_Time,
    Action: "Update_User",
    Is_Visited: data.isVisited === true ? 1 : 0,
    TableName: "QMS_Device",
  });

  var config = {
    method: "post",
    url: `${process.env.REACT_APP_isVisited}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      // console.log(error);
    });
};

// export const sendCountNumber = (data) => {
//   const timeStamp = moment(new Date()).format("YYYY-MM-DD/HH:mm:ss:SSSS");
//   return httpservice()
//     .post(`${process.env.REACT_APP_storingdata}`, {
//       TableName: "QMS_Device",
//       Data: `[${data.deviceName.replace(/[.-]/g, "")}_Device,${timeStamp}, ${
//         data.count
//       }]`,
//       Find: "", //Device details goes here
//     })
//     .then(function (response) {
//       return response.data;
//     })
//     .catch(function (error) {
//       return error.response && error.response.data
//         ? error.response.data
//         : error;
//     });
// };

// export const senWebNotification = (data) => {
//   return httpservice()
//     .post(`${process.env.REACT_APP_notificationData}`, {
//       Device_Name: data.Device_Name,
//       Date: data.Date_Time,
//       Count: data.Count,
//     })
//     .then(function (response) {
//       return response.data;
//     })
//     .catch(function (error) {
//       return error.response && error.response.data
//         ? error.response.data
//         : error;
//     });
// };

export const saveUserPrefenceSetting = (data) => {
  // console.log("saveUserPrefenceSettingdata", data);
  return httpservice()
    .post(`${process.env.REACT_APP_userPreference}`, data)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};

// export const getUserPreferenceSetting = (data) => {
//   // console.log("getUserPreferenceSetting", data);
//   return httpservice()
//     .post(`${process.env.REACT_APP_storingdata}`, data)
//     .then(function (response) {
//       return response.data;
//     })
//     .catch(function (error) {
//       return error.response && error.response.data
//         ? error.response.data
//         : error;
//     });
// };

export const getVoiceMessage = (data) => {
  return httpservice()
    .post(`${process.env.REACT_APP_getVoiceMessage}`, data)
    .then(function (response) {
      // console.log('response :', response);
      // console.log("getVoiceMessage",response);

      return response;
    })
    .catch(function (error) {
      return error;
    });
};

export const removeMeWaitlistQueue = (data) => {
  // console.log("removeuserRegistrationDateTimeIndex", data);

  return httpservice()
    .post(`${process.env.REACT_APP_removefromwaitlist}`, data)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};

export const removeUnconfirmedUser = (data) => {
  // var data = JSON.stringify({
  //   Device_Type: "QMD",
  //   Action: "save_image",
  //   Device: name,
  //   Image: image,
  // });
  var config = {
    method: "delete",
    url: `https://qyirhwyg0b.execute-api.eu-central-1.amazonaws.com/deleteAccount?email=${data}&device=qmd&status=UNCONFIRMED`,
    headers: {},
  };

  return axios(config)
    .then(function (response) {
      // console.log("response==>>", response);
      // console.log(JSON.stringify(response.data));
      return response;
    })
    .catch(function (error) {
      // console.log(error);
      return error;
    });
};
export const sendUrlToBackendHandler = (data, hashId) => {
  // console.log("sendUrlToBackendHandler", data);
  // console.log("sendUrlToBackendHandler", hashId);
  var data = JSON.stringify({
    Device_Name: data,
    Registration_Url: hashId,
  });

  var config = {
    method: "post",
    url: `${process.env.REACT_APP_storequeueurl}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios(config)
    .then(function (response) {
      // console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      // console.log(error);
    });
};

export const storeFormData = (data) => {
  return httpservice()
    .post(`${process.env.REACT_APP_storeFormData}`, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};

export const getFormData = (data) => {
  return httpservice()
    .put(`${process.env.REACT_APP_storeFormData}`, data)
    .then(function (response) {
      // console.log("apigetFormData",response)
      return response.data;
    })
    .catch(function (error) {
      return error.response && error.response.data
        ? error.response.data
        : error;
    });
};

export const getDelayTime = (data) => {
  var apiData = data + "_Delay";
  console.log("getDelayTime", apiData);
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_DelaytimeData}/${apiData}`,
    // url: "https://1y78mnf8yk.execute-api.eu-central-1.amazonaws.com/default/rajeshchoudhary_sep111807_sep111807_Delay",
    headers: {},
    // data : data
  };

  return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
      return response;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const whatsUpMessage = (propData) => {
  console.log("whatsupProData", propData);
  const data = JSON.stringify({
    Action: "Send_Message",
    DateTime: propData.dateTime,
    Name: propData.name,
    Device_Name: `${propData.deviceName + "_App"}`,
    Contact: propData.number.trim(),
  });
  console.log("whatsupProData", data);

  var config = {
    method: "post",
    url: process.env.REACT_APP_WhatsUpMessage,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  return axios(config)
    .then(function (response) {
      console.log("whatsup", response);
    })
    .catch(function (err) {
      console.log("whatsup", err);
    });
};
