import React, { Component } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './CroppieModal.css'
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ZoomInOutlinedIcon from '@material-ui/icons/ZoomInOutlined';
import ZoomOutOutlinedIcon from '@material-ui/icons/ZoomOutOutlined';
import RotateLeftOutlinedIcon from '@material-ui/icons/RotateLeftOutlined';
import RotateRightOutlinedIcon from '@material-ui/icons/RotateRightOutlined';
// import PageHeader from '../common/PageHeader';
import UploadingImage from '../assests/images/UploadingImage.gif';

const reader = (file) => {
  // console.log(file)
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.readAsDataURL(file);
  });
}

class CroppieModal extends Component {
  constructor(props) {
    super(props);
    this.state = { imgURL: '', croppedImg: '' };
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.rotateLeft = this.rotateLeft.bind(this);
    this.rotateRight = this.rotateRight.bind(this);
    this.resetCrop = this.resetCrop.bind(this);
    this.resetCrop = this.resetCrop.bind(this);
  }

  componentDidMount() {
    reader(this.props.image).then(result => {
      this.setState(state => ({ ...state, imgURL: result }));
    });
  }

  componentDidUpdate() {
    reader(this.props.image).then(result => {
      this.setState(state => ({ ...state, imgURL: result }));
    });
  }

  _crop() {
    let croppedImg = this.cropper.getCroppedCanvas({
      width: 200,
      height: 200,
      minWidth: 200,
      minHeight: 200,
      maxWidth: 400,
      maxHeight: 400,
      imageSmoothingEnabled: false,
      imageSmoothingQuality: 'high',
    }).toDataURL();
    // console.log("123456y7u8iopokijuhgvfcvbn",croppedImg)
    this.setState(state => ({ ...state, croppedImg }));
    this.props.setImage(croppedImg)
  }

  onCropperInit(cropper) { this.cropper = cropper; }
  showPreview() { this.setState(state => ({ ...state, showPreview: !state.showPreview })) }

  zoomIn() { this.cropper.zoom(0.1); }
  zoomOut() { this.cropper.zoom(-0.1); }
  rotateRight() { this.cropper.rotate(-10); }
  rotateLeft() { this.cropper.rotate(10); }
  resetCrop() { this.cropper.reset(); }

  render() {
    return (
      <>
        {this.props.error && <span style={{ color: "red", fontSize: "12px" }}>{this.props.error}</span>}
        <p>{this.props.modalTitle}</p>
        <div className="root">
          {
            this.props.uploading &&
            <div className="uploadingImageLoader">
              <img src={UploadingImage} alt='uploading' />
            </div>
          }
          <Cropper
            src={this.state.imgURL}
            style={{ height: 205, width: '100%' }}
            initialAspectRatio={1}
            aspectRatio={1}
            guides={false}
            crop={this._crop.bind(this)}
            onInitialized={this.onCropperInit.bind(this)}
            minCropBoxHeight={200}
            minCropBoxWidth={200}
          />
        </div>
        <div className="cropModalBtn">
          <Grid container>
            <Grid item xs={6}>
              <div align={'left'}>
                <Tooltip arrow title='Zoom in'>
                  <IconButton onClick={this.zoomIn} tabIndex={1}><ZoomInOutlinedIcon /></IconButton>
                </Tooltip>
                <Tooltip arrow title='Zoom out'>
                  <IconButton onClick={this.zoomOut} tabIndex={1}><ZoomOutOutlinedIcon /></IconButton>
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div align={'right'}>
                <Tooltip arrow title='Rotate left'>
                  <IconButton onClick={this.rotateRight} tabIndex={1}><RotateLeftOutlinedIcon /></IconButton>
                </Tooltip>
                <Tooltip arrow title='Rotate right'>
                  <IconButton onClick={this.rotateLeft} tabIndex={1}><RotateRightOutlinedIcon /></IconButton>
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default CroppieModal;