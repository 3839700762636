import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  MenuItem,
  Button,
  Grid,
  Card,
  CardContent,
  Divider,
} from "@material-ui/core";
import { Header } from "../component/analytics/Header";

// import { HeaderWithoutBack } from "./HeaderWithoutBack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Clear } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import Select from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { getFormData, storeFormData } from "../api";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@mui/material/Alert";

const CustomForm = () => {
  let { id } = useParams();
  const deviceName = atob(id);
  const [formFields, setFormFields] = useState([
    { id: 1, fieldName: "", type: "text", options: [] },
  ]);
  const [open, setOpen] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  React.useEffect(() => {
    let name = deviceName.replace(".", "");
    const data = {
      Device_Name: name + "_FormData",
    };
    getFormData(data).then((res) => {
      if (res.formData && res.formData.Form_Field) {
        setFormFields(res.formData.Form_Field);
      }
    });
  }, [id]);

  const handleQuestionChange = (e, id) => {
    const updatedFields = formFields.map((field) => {
      if (field.id === id) {
        return { ...field, fieldName: e.target.value };
      }
      return field;
    });
    setFormFields(updatedFields);
  };

  const handleTypeChange = (e, id) => {
    const updatedFields = formFields.map((field) => {
      if (field.id === id) {
        return { ...field, type: e.target.value, options: [] };
      }
      return field;
    });
    setFormFields(updatedFields);
  };

  const handleOptionChange = (e, fieldId, optionId) => {
    const updatedFields = formFields.map((field) => {
      if (field.id === fieldId) {
        const updatedOptions = field.options.map((option) => {
          if (option.id === optionId) {
            return { ...option, value: e.target.value };
          }
          return option;
        });
        return { ...field, options: updatedOptions };
      }
      return field;
    });
    setFormFields(updatedFields);
  };

  const addField = () => {
    const newField = {
      id: Date.now(),
      fieldName: "",
      type: "text",
      options: [],
    };
    setFormFields([...formFields, newField]);
  };

  const removeField = (id) => {
    const updatedFields = formFields.filter((field) => field.id !== id);
    setFormFields(updatedFields);
  };

  const addOption = (fieldId) => {
    const updatedFields = formFields.map((field) => {
      if (field.id === fieldId) {
        const newOption = { id: Date.now(), value: "" };
        return { ...field, options: [...field.options, newOption] };
      }
      return field;
    });
    setFormFields(updatedFields);
  };

  const removeOption = (fieldId, optionId) => {
    const updatedFields = formFields.map((field) => {
      if (field.id === fieldId) {
        const updatedOptions = field.options.filter(
          (option) => option.id !== optionId
        );
        return { ...field, options: updatedOptions };
      }
      return field;
    });
    setFormFields(updatedFields);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let name = deviceName.replace(".", "");
    const data = {
      Device_Name: name + "_FormData",
      Form_Field: formFields,
    };
    // console.log("storeFormDataformData", formFields);
    // formFields.options.map((item) => {
    //   console.log("storeFormDataformData", item);
    // });
    storeFormData(data).then((res) => {
      // console.log("res", res);
      if (res.status == 203) {
        // console.log("setAlert");
        setOpenAlert(true);
      } else {
        setOpen(true);
      }
    });
    //
    // console.log(formFields);
  };

  return (
    <div>
      {/* <Header /> */}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={open}
          autoHideDuration={2500}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Settings saved successfully
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openAlert}
          autoHideDuration={2500}
          onClose={handleCloseAlert}
        >
          <Alert onClose={handleCloseAlert} severity="error">
            Duplicate Field Found
          </Alert>
        </Snackbar>

        <form onSubmit={handleSubmit} style={{ width: "90%" }}>
          <Grid
            container
            spacing={2}
            style={{ textAlign: "center", marginTop: "10px" }}
          >
            <Grid
              item
              xs={12}
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <TextField label="Name" fullWidth value="Name" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <Select value="text" disabled={true}>
                          <MenuItem value="text">Text</MenuItem>
                          <MenuItem value="number">Number</MenuItem>
                          <MenuItem value="checkbox">Checkbox</MenuItem>
                          <MenuItem value="select">Select</MenuItem>
                          <MenuItem value="age">Age</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ marginBottom: "10px", marginTop: "10px" }}
            >
              <Card>
                <CardContent>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Phone Number"
                        fullWidth
                        value="Phone Number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth>
                        <Select value="number" disabled={true}>
                          <MenuItem value="text">Text</MenuItem>
                          <MenuItem value="number">Number</MenuItem>
                          <MenuItem value="checkbox">Checkbox</MenuItem>
                          <MenuItem value="select">Select</MenuItem>
                          <MenuItem value="age">Age</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            {
              formFields.length === 0 ? (
                <Grid item xs={12} style={{display:'flex', justifyContent: 'end'}}>
                  <Tooltip title="Add Field">
                    <Button
                      startIcon={<AddCircleOutlineIcon />}
                      onClick={addField}
                    ></Button>
                  </Tooltip>
                </Grid>
              ) : (
                formFields.map((field) => (
                  <Grid
                    item
                    xs={12}
                    key={field.id}
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                  >
                    <Card>
                      <CardContent>
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12} sm={6}>
                            <TextField
                              label="Field Name"
                              fullWidth
                              value={field.fieldName}
                              onChange={(e) => handleQuestionChange(e, field.id)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                              <Select
                                value={field.type}
                                onChange={(e) => handleTypeChange(e, field.id)}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="text">Text</MenuItem>
                                <MenuItem value="number">Number</MenuItem>
                                <MenuItem value="checkbox">Checkbox</MenuItem>
                                <MenuItem value="select">Select</MenuItem>
                                <MenuItem value="age">Age</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {field.type !== "text" &&
                            field.type !== "number" &&
                            field.type !== "age" && (
                              <Grid item xs={12} sm={2}>
                                <Tooltip title="Add Options">
                                  <Button
                                    startIcon={<AddIcon />}
                                    onClick={() => addOption(field.id)}
                                  ></Button>
                                </Tooltip>
                              </Grid>
                            )}
                          {field.type !== "text" &&
                            field.type !== "number" &&
                            field.type !== "age" && (
                              <>
                                {field.options.map((option) => (
                                  <>
                                    <Grid
                                      item
                                      xs={12}
                                      sm={6}
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <TextField
                                        key={option.id}
                                        label="Option"
                                        fullWidth
                                        value={option.value}
                                        onChange={(e) =>
                                          handleOptionChange(e, field.id, option.id)
                                        }
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                      <Tooltip title="Delete Option">
                                        <Button
                                          key={option.id}
                                          onClick={() =>
                                            removeOption(field.id, option.id)
                                          }
                                        >
                                          <Clear />
                                        </Button>
                                      </Tooltip>
                                    </Grid>
                                  </>
                                ))}
                              </>
                            )}
                        </Grid>
                        <Divider style={{ marginTop: "32px" }} />
                        <div
                          style={{
                            marginTop: "10px",
                            textAlign: "right",
                            marginBottom: "-13px",
                          }}
                        >
                          <Tooltip title="Delete Field">
                            <Button
                              startIcon={<DeleteIcon />}
                              onClick={() => removeField(field.id)}
                            ></Button>
                          </Tooltip>
                          <Tooltip title="Add Field">
                            <Button
                              startIcon={<AddCircleOutlineIcon />}
                              onClick={addField}
                            ></Button>
                          </Tooltip>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                )))

            }
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary" style={{ backgroundColor: "rgb(6, 5, 67)" }}>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default CustomForm;
