import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { colorStyles } from "../assests/css/color";
import {
  CircularProgress
} from "@material-ui/core";
import { ReactSVG } from "react-svg";
import Logo from "../assests/images/habilelabs_full_logo.svg";
import habilelabsLogo from "../assests/images/habilelabs-logo.svg"



const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: colorStyles.primaryBackgroundColor,
    height: 80,
    position: "sticky",
    top: 0,
    zIndex: 10
  },
}));
export function HeaderMock() {
  const classes = useStyles();
  const [img, setImg] = React.useState(localStorage.getItem("img"));
  const [isLoading, setIsLoading] = React.useState(false);

  // React.useEffect(() => {
  //   setImg(localStorage.getItem("img"));
  // }, [img]);

  // console.log("img", img)
  const finalimg = localStorage.getItem('img')


  React.useEffect(() => {
    if (finalimg) {
      setImg(localStorage.getItem('img'))
      setIsLoading(false)
    }

    else { setIsLoading(true) }
  }, [finalimg]);
  return (
    <div className={classes.header} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div>
        <div
          style={{
            position: "relative",
            width: "100%",
          }}
        >

          {/* <img style={{
            height: 65,
            width: 65,
            textAlign: "center",
            marginTop: 7,
            borderRadius: '52px'
          }}
            src={Logo}
            key={img}
            name="file"
            className="img-thumbnail"
            height={100}
            width={100}
            alt="profile" /> */}
          <ReactSVG src={habilelabsLogo} />
          {/* }         <span> */}
        </div>
      </div>
    </div>
  );
}
